import { ComponentProps } from "react"
import {
  AutocompleteInput,
  ReferenceInput,
  useResourceContext,
  useTranslate,
} from "react-admin"
import { NIL } from "uuid"
export const CrewMemberInput = ({
  organization_id,
  source,
  showAutoToolroom = true,
  label,
  ...props
}: {
  organization_id: string
  showAutoToolroom?: boolean
  label?: string
} & Omit<ComponentProps<typeof AutocompleteInput>, "label">) => {
  const resource = useResourceContext()
  const translate = useTranslate()
  const orgFilter = showAutoToolroom
    ? [NIL, organization_id]
    : [organization_id]
  return (
    <ReferenceInput
      reference="crew_members"
      source={source}
      filter={{
        "organization_id@in": orgFilter,
        "role@neq": "support",
        "deleted_at@is": null,
      }}
    >
      <AutocompleteInput
        label={translate(label ?? `resources.${resource}.fields.${source}`, {
          smart_count: 1,
        })}
        fullWidth
        source="name"
        optionText="name"
        filterToQuery={(query) => ({
          "name@ilike": query,
        })}
        {...props}
      />
    </ReferenceInput>
  )
}
