import {
  DialogContent,
  ListItem,
  ListItemText,
  styled,
  Typography,
} from "@mui/material"
import {
  EventRecord,
  TimelineItemProps,
  useEventLabel,
  UseEventLabelOptions,
} from "@react-admin/ra-audit-log"
import { ReactElement, useCallback, useMemo, useState } from "react"
import { useLocaleState, useRecordContext, useTranslate } from "react-admin"
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../Components/BootstrapDialog"
import { labelFromInactiveStatus } from "./inactiveStatuses"
import { labelFromToolState, ToolState } from "./toolStates"

/**
 * Default component to display an audit logs.
 * @see Timeline
 */
export const RecordTimelineItem = (
  props: RecordTimelineItemProps
): ReactElement => {
  const [locale] = useLocaleState()

  const { record: recordProp, ...rest } = props
  const translate = useTranslate()
  const record = useRecordContext<EventRecord>(recordProp)
  const actionLabel = useToolTransferLabel({ record, variant: "record" })
  const [open, setOpen] = useState(false)
  const onClose = useCallback(() => setOpen(false), [setOpen])
  const showImage = useMemo(
    () =>
      // Legacy record format
      (record.action === "legacy_transfer" &&
        record.payload.data?.transfer_photo) ||
      (record.payload.data.state === ToolState.Transferring &&
        record.payload.data.transfer_photo != null &&
        record.payload.data.transfer_photo !==
          record.payload.previousData?.transfer_photo) ||
      (record.payload.previousData?.state === ToolState.Auditing &&
        record.payload.data?.state === ToolState.Active &&
        record.payload.data?.transfer_photo !==
          record.payload.previousData?.transfer_photo),
    [
      record.action,
      record.payload.data.state,
      record.payload.data.transfer_photo,
      record.payload.previousData?.state,
      record.payload.previousData?.transfer_photo,
    ]
  )
  const byLine = useMemo(
    () =>
      record.author
        ? // TODO: actually fix the byLine typo here
          " - " + record.author?.fullName.replace("))", ")") ||
          translate(`ra-audit-log.author`, record.author)
        : "",
    [record.author, translate]
  )
  return (
    actionLabel && (
      <Root dense disableGutters {...rest}>
        <ListItemText
          primary={
            <div className={RecordTimelineItemClasses.authorContainer}>
              {/* <EventAvatar
              className={RecordTimelineItemClasses.avatar}
              alt={record.author.fullName}
              src={record.payload.data.transfer_photo}
              fullName={record.author.fullName}
              role="presentation"
              size="medium"
            /> */}
              {showImage && (
                <>
                  <img
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setOpen(true)
                    }}
                    title={actionLabel}
                    alt={actionLabel}
                    src={record.payload.data.transfer_photo}
                    className={RecordTimelineItemClasses.image}
                  />
                  <BootstrapDialog
                    // fullScreen
                    // className={RecordTimelineItemClasses.dialog}
                    fullWidth
                    maxWidth="md"
                    open={open}
                    onClose={onClose}
                    onClick={(e) => {
                      setOpen(false)
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <BootstrapDialogTitle onClose={onClose}>
                      {actionLabel}
                    </BootstrapDialogTitle>
                    <DialogContent>
                      <img
                        title={actionLabel}
                        alt={actionLabel}
                        src={record.payload.data.transfer_photo}
                        //
                        style={{ width: "100%" }}
                      />
                    </DialogContent>
                  </BootstrapDialog>
                </>
              )}
              <Typography
                color="textPrimary"
                className={RecordTimelineItemClasses.content}
              >
                <strong className={RecordTimelineItemClasses.author}>
                  {actionLabel}
                </strong>
              </Typography>
            </div>
          }
          secondary={
            <>
              {/* <div className={RecordTimelineItemClasses.action}>
              {actionLabel}
            </div> */}
              <div className={RecordTimelineItemClasses.date}>
                {new Date(record.date).toLocaleString(locale) + byLine}
              </div>
            </>
          }
        />
      </Root>
    )
  )
}

const PREFIX = "RaRecordTimelineItem"
const RecordTimelineItemClasses = {
  content: `${PREFIX}-content`,
  authorContainer: `${PREFIX}-authorContainer`,
  avatar: `${PREFIX}-avatar`,
  dialog: `${PREFIX}-dialog`,
  image: `${PREFIX}-image`,
  author: `${PREFIX}-author`,
  action: `${PREFIX}-action`,
  date: `${PREFIX}-date`,
}

const Root = styled(ListItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${RecordTimelineItemClasses.content}`]: {
    display: "flex",
    flexWrap: "wrap",
  },
  [`& .${RecordTimelineItemClasses.authorContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${RecordTimelineItemClasses.avatar}`]: {
    marginRight: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  [`& .${RecordTimelineItemClasses.author}`]: {},
  [`& .${RecordTimelineItemClasses.action}`]: {},
  [`& .${RecordTimelineItemClasses.date}`]: {},
  [`& .${RecordTimelineItemClasses.image}`]: {
    margin: "0.25rem",
    width: 50,
    height: 50,
    objectFit: "contain",
  },
}))

interface RecordTimelineItemProps
  extends Omit<TimelineItemProps, "link" | "button"> {
  record?: EventRecord
}

const useToolTransferLabel = (options: UseEventLabelOptions): string => {
  const translate = useTranslate()
  const label = useEventLabel(options)
  const { payload } = options.record
  const { data, previousData, sender, previousSender, recipient, project } =
    payload || {}
  if (label === "ra-audit-log.record_event.legacy_transfer") {
    if (data.sender === data.recipient) {
      return translate("ra-audit-log.record_event.transfer.change_project", {
        project,
      })
    }
    if (data.sender == null) {
      return translate("ra-audit-log.record_event.legacy_transfer.add_tool", {
        recipient,
      })
    }
    return translate("ra-audit-log.record_event.legacy_transfer.transfer", {
      sender,
      recipient,
    })
  }

  if (!previousData) {
    return translate("ra-audit-log.record_event.tool.create", {
      sender,
    })
  }
  if (!data) {
    return translate("ra-audit-log.record_event.tool.delete", {
      sender,
    })
  }
  if (data.state !== previousData.state) {
    switch (data.state) {
      case ToolState.Active:
        if (
          previousData.state === ToolState.Requested ||
          previousData.state === ToolState.Transferring
        ) {
          if (previousData.sender_id === data.sender_id) {
            return translate("ra-audit-log.record_event.transfer.cancelled", {
              action: labelFromToolState(previousData.state),
              sender,
            })
          } else {
            return translate("ra-audit-log.record_event.transfer.accepted", {
              recipient: sender,
              sender: previousSender,
            })
          }
        } else if (
          previousData.state === ToolState.Auditing &&
          data.state === ToolState.Active &&
          previousData.transfer_photo !== data.transfer_photo
        ) {
          return translate("ra-audit-log.record_event.transfer.audited", {
            sender,
          })
        }
        // TODO: Add other cases
        return label
      case ToolState.Auditing:
        return translate("ra-audit-log.record_event.transfer.auditing", {
          sender,
        })
      case ToolState.Inactive:
        return translate("ra-audit-log.record_event.transfer.inactive", {
          status: labelFromInactiveStatus(data.inactive_status),
        })
      case ToolState.Requested:
        return translate("ra-audit-log.record_event.transfer.requested", {
          sender,
          recipient,
        })
      case ToolState.Transferring:
        return translate("ra-audit-log.record_event.transfer.transferring", {
          sender,
          recipient,
        })
      default:
        return label
    }
  }
  // Transfers without confirmation
  if (
    previousData.state === ToolState.Active &&
    data.state === ToolState.Active
  ) {
    if (previousData.sender_id !== data.sender_id) {
      return translate("ra-audit-log.record_event.transfer.transferred", {
        sender: previousSender,
        recipient: sender,
      })

      // TODO: Handle project case
    }
    if (data.project_id !== previousData.project_id) {
      return translate("ra-audit-log.record_event.transfer.change_project", {
        project,
      })
    }
  }
  if (label === "Updated field Last Modified") return "Updated"
  return label.replace(" fields ", " ").replace(", Last Modified", "")
}
