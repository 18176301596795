import { Create } from "react-admin"
import { ToolForm } from "./ToolForm"

export function ToolCreate() {
  return (
    <Create>
      <ToolForm />
    </Create>
  )
}
