import { Edit, SimpleForm } from "@react-admin/ra-rbac"
import { useEffect } from "react"
import {
  ImageField,
  ImageInput,
  TextInput,
  useRecordContext,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
export function OrganizationEdit() {
  return (
    <Edit>
      <SetOrgToRecordOrg>
        <SimpleForm>
          <ImageInput source="logo">
            <ImageField source="src" />
          </ImageInput>
          <TextInput source="name" />
        </SimpleForm>
      </SetOrgToRecordOrg>
    </Edit>
  )
}

const SetOrgToRecordOrg = ({ children }) => {
  const { id } = useRecordContext()
  const [, setOrganizationId] = useOrganizationId()
  useEffect(() => {
    if (!id) return
    setOrganizationId(id as string)
  }, [id, setOrganizationId])
  return children
}
