import { Show, SimpleShowLayout } from "@react-admin/ra-rbac"
import {
  EmailField,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  TextField,
} from "react-admin"
import { ActionButtonsWithExport } from "../Tool/ActionButtonsWithExport"
import { ToolDatagrid } from "../Tool/ToolList"
import { prettyPhoneFromPhoneNumber } from "../prettyPhoneFromPhoneNumber"
import { roles } from "../roles"

export const CrewMemberShow = () => {
  return (
    <Show actions={<ActionButtonsWithExport exportTarget="sender_id" />}>
      <SimpleShowLayout>
        {/* <ImageField source="profile_picture_url" /> */}
        <TextField source="name" />
        <FunctionField
          source="phone_number"
          render={prettyPhoneFromPhoneNumber}
        />
        <EmailField source="email" />
        <SelectField source="role" choices={roles} />
        <ReferenceField source="default_project_id" reference="projects" />

        <ReferenceManyField
          reference="tools"
          target="sender_id"
          label="Assigned"
          filter={{ "deleted_at@is": null }}
        >
          <ToolDatagrid
            sort={{ field: "updated_at", order: "DESC" }}
            bulkActionButtons={false}
            rowClick="show"
          />
        </ReferenceManyField>
        {/* <ReferenceManyField
          reference="tools"
          target="recipient_id"
          label="Open Transfers"
          filter={{ "deleted_at@is": null }}
        >
          <ToolDatagrid
            sort={{ field: "updated_at", order: "DESC" }}
            bulkActionButtons={false}
            rowClick="show"
            header={() => null}
          />
        </ReferenceManyField> */}
      </SimpleShowLayout>
    </Show>
  )
}
