export enum ToolState {
  Active = "ACTIVE",
  Auditing = "AUDITING",
  Inactive = "INACTIVE",
  Requested = "REQUESTED",
  Transferring = "TRANSFERRING",
}

export const activeOrInactive = [
  { id: ToolState.Active, name: "Active" },
  { id: ToolState.Inactive, name: "Inactive" },
]
export const toolStates = [
  ...activeOrInactive,
  { id: ToolState.Auditing, name: "Auditing" },
  { id: ToolState.Requested, name: "Requested" },
  { id: ToolState.Transferring, name: "Transferring" },
]

export const labelFromToolState = (state: ToolState) => {
  const toolState = toolStates.find(({ id }) => id === state)
  return toolState?.name
}
