import { required } from "ra-core"
import { TextInput } from "react-admin"
import { useSearchParams } from "react-router-dom"
import { SupabaseAuthForm } from "./SupabaseAuthForm"

export const OtpForm = ({
  redirectTo = "/",
  className,
}: {
  redirectTo?: string
  className?: string
}) => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get("email")
  const code = searchParams.get("code")

  return (
    <SupabaseAuthForm
      redirectTo={redirectTo}
      className={className}
      submitLabel="Verify Code"
    >
      <p>
        Enter the code sent to your email below to complete the login process
      </p>
      <TextInput
        autoFocus
        variant="outlined"
        source="email"
        label="Email"
        defaultValue={email}
        validate={required()}
        parse={(value) => value.trim().toLowerCase()}
        fullWidth
      />
      <TextInput
        autoFocus
        variant="outlined"
        source="token"
        label="Code"
        defaultValue={code}
        validate={required()}
        fullWidth
      />
    </SupabaseAuthForm>
  )
}
