import { useDisclosure } from "@dwarvesf/react-hooks"
import DeleteIcon from "@mui/icons-material/Delete"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import {
  BulkDeleteButton,
  Button,
  SaveButton,
  SimpleForm,
  Toolbar,
  useDeleteMany,
  useGetList,
  useListContext,
  useTranslate,
  useUpdateMany,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { CrewMemberInput } from "../Tool/CrewMemberInput"
import { ProjectInput } from "../Tool/ProjectInput"

export function BulkDeleteAndTransferButton({ source }: { source?: string }) {
  const translate = useTranslate()
  const [organization_id] = useOrganizationId()
  const { selectedIds, resource } = useListContext()
  const resourceId = source || `${resource.slice(0, -1)}_id`
  const {
    data: tools,
    isLoading,
    error,
  } = useGetList("tools", {
    filter: {
      [`${resourceId}@in`]: selectedIds,
      organization_id,
      "deleted_at@is": "NULL",
    },
  })
  const [deleteMany, { isLoading: isLoadingDelete, error: errorDelete }] =
    useDeleteMany(resource, {
      ids: selectedIds,
    })
  const [updateMany] = useUpdateMany()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleClick = (data) => {
    if (!data) return
    updateMany("tools", {
      ids: tools.map((tool) => tool.id),
      data,
    })
    deleteMany()
  }
  if (error || errorDelete) {
    return <p>ERROR</p>
  }
  if (tools === undefined) return null
  if (tools.length === 0) return <BulkDeleteButton />
  return (
    <>
      <Button
        sx={{ color: "red" }}
        size="medium"
        disabled={isLoading || isLoadingDelete}
        onClick={onOpen}
        startIcon={<DeleteIcon />}
        label="DELETE"
      />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Transfer To</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Transfer tools assigned to selected{" "}
            {translate(`resources.${resource}.name`, {
              smart_count: selectedIds.length,
            }).toLowerCase()}{" "}
            to another{" "}
            {translate(`resources.${resource}.name`, {
              smart_count: 1,
            }).toLowerCase()}
          </DialogContentText>
          <SimpleForm
            onSubmit={handleClick}
            toolbar={
              <Toolbar sx={{ justifyContent: "space-between" }}>
                <Button onClick={onClose} label="Cancel" />
                <SaveButton onClick={onClose} label="Transfer" />
              </Toolbar>
            }
          >
            <CrewMemberInput
              source="sender_id"
              organization_id={organization_id}
            />
            <ProjectInput
              source="project_id"
              organization_id={organization_id}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
