import {
  ImageField,
  ImageInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useGetIdentity,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"

export function ToolNoteForm({ hideTool = false }: { hideTool?: boolean }) {
  const [organization_id] = useOrganizationId()
  useGetIdentity()

  return (
    <SimpleForm
      defaultValues={{
        organization_id,
      }}
    >
      {hideTool ? null : <ReferenceInput source="tool_id" reference="tools" />}
      <ImageInput source="photo">
        <ImageField source="src" />
      </ImageInput>
      <TextInput multiline source="note" />
    </SimpleForm>
  )
}
