import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { parsePhoneNumber } from "libphonenumber-js"
import * as React from "react"
import { useCallback } from "react"
import { useGetIdentity, useLogout, useStore } from "react-admin"
import { supabase } from "../supabase"

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.tooltribe.com/">
        TOOLTRIBE
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export function SignUp() {
  const { identity } = useGetIdentity()
  const email = identity?.email
  const [error, setError] = React.useState<string | null>(null)
  const [, setOrganizationId] = useStore("organizationId")
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const data = new FormData(event.currentTarget)
      const name = data.get("name")
      const orgName = data.get("orgName")
      const phone = data.get("phone") as string
      // We don't use the form email.

      if (!name || !orgName || !phone || !email) {
        setError("All fields are required")
        return
      }
      if ((orgName as string).length < 3) {
        setError("Organization name must be at least 3 characters")
        return
      }
      if ((name as string).length < 3) {
        setError("Your name must be at least 3 characters")
        return
      }
      const phoneNumber = parsePhoneNumber(phone, "US")
      if (!phoneNumber) {
        setError("Mobile phone number is required")
        return
      }
      if (!phoneNumber.isValid()) {
        setError("Mobile phone number is invalid")
        return
      }
      // We don't need to check email because it's already validated by auth

      const phone_number = phoneNumber.format("E.164")
      supabase.auth.updateUser({ data: { name: name.toString() } })
      const { error, data: organizationId } = await supabase.rpc(
        "create_organization",
        {
          name,
          phone_number,
          email,
          org_name: orgName,
        }
      )

      if (error) {
        if (
          error.message ===
          'duplicate key value violates unique constraint "users_phone_key"'
        ) {
          setError(
            "Phone number already exists. Please contact support@tooltribe.com."
          )
          return
        }
        setError(error.message)
        return
      }
      setOrganizationId(organizationId)
      await supabase.auth.refreshSession()
      window.location.reload()
    },
    [email, setOrganizationId]
  )
  const logout = useLogout()
  const logoutClick = useCallback(() => {
    logout(null, "/login")
  }, [logout])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/images/tooltribe-bw.webp"
          alt="TOOLTRIBE Logo"
          width={200}
          // height={200}
        />

        <Typography component="h1" variant="h5">
          Create a new organization
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="orgName"
                required
                fullWidth
                id="orgName"
                label="Organization Name"
                autoFocus
                autoComplete="organization"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="name"
                label="Your Name"
                name="name"
                autoComplete="name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                required
                fullWidth
                value={email || ""}
                label="Email Address"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="phone"
                label="Mobile Phone Number"
                type="phone"
                id="phone"
                autoComplete="tel"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: "#CCFF33", color: "black" }}
          >
            Sign Up
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" variant="body2" onClick={logoutClick}>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  )
}
