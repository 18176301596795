import {
  EditButton,
  ExportButton,
  TopToolbar,
  useRecordContext,
} from "react-admin"
import { useToolsExporter } from "./toolsExporter"

export function ActionButtonsWithExport({
  exportTarget,
  children,
}: {
  exportTarget: string
  children?: React.ReactNode
}) {
  const record = useRecordContext()
  const toolsExporter = useToolsExporter()
  return (
    <TopToolbar>
      <EditButton />
      {/* Add your custom actions */}
      <ExportButton
        exporter={toolsExporter}
        resource="tools"
        filterValues={{ [exportTarget]: record.id }}
      />
      {children}
    </TopToolbar>
  )
}
