import Button from "@mui/material/Button"
import { useRecordContext, useRedirect } from "react-admin"

export function OrganizationButton({ setOrganizationId }: any) {
  const record = useRecordContext()
  const redirect = useRedirect()
  return (
    <Button
      sx={{
        textAlign: "left",
      }}
      variant="text"
      onClick={() => {
        setOrganizationId(record.organization_id)
        redirect("/")
      }}
    >
      {record.name}
    </Button>
  )
}
