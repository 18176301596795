import { useDisclosure } from "@dwarvesf/react-hooks"
import DeleteIcon from "@mui/icons-material/Delete"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import {
  Button,
  DeleteButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  useDelete,
  useGetList,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useTranslate,
  useUpdateMany,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { CrewMemberInput } from "../Tool/CrewMemberInput"
import { ProjectInput } from "../Tool/ProjectInput"

export function DeleteAndTransferButton({ source }: { source?: string }) {
  const translate = useTranslate()

  const [organization_id] = useOrganizationId()
  const { id } = useRecordContext()
  const resources = useResourceContext()
  const resourceId = source || `${resources.slice(0, -1)}_id`

  const {
    data: tools,
    isLoading,
    error,
  } = useGetList("tools", {
    filter: { [resourceId]: id, organization_id, "deleted_at@is": "NULL" },
  })

  const [deleteOne, { isLoading: isLoadingDelete, error: errorDelete }] =
    useDelete(resources, {
      id: id,
    })
  const [updateMany] = useUpdateMany()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const redirect = useRedirect()

  if (error || errorDelete) {
    return <p>ERROR</p>
  }
  const handleClick = (data) => {
    if (!tools) return
    updateMany("tools", {
      ids: tools.map((tool) => tool.id),
      data: {
        sender_id: data.sender_id,
        project_id: data.project_id,
      },
    })
    deleteOne()
    redirect("list", "crew_members")
  }
  if (tools === undefined) return null
  if (tools.length === 0) return <DeleteButton />
  return (
    <>
      <Button
        sx={{ color: "red" }}
        size="medium"
        disabled={isLoading || isLoadingDelete}
        onClick={onOpen}
        startIcon={<DeleteIcon />}
        label="DELETE"
      />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Transfer To</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Transfer tools assigned to selected{" "}
            {translate(`resources.${resources}.name`, {
              smart_count: 1,
            }).toLowerCase()}{" "}
            to another{" "}
            {translate(`resources.${resources}.name`, {
              smart_count: 1,
            }).toLowerCase()}
          </DialogContentText>
          <SimpleForm
            onSubmit={handleClick}
            toolbar={
              <Toolbar sx={{ justifyContent: "space-between" }}>
                <Button onClick={onClose} label="Cancel" />
                <SaveButton onClick={onClose} label="Transfer" />
              </Toolbar>
            }
          >
            <CrewMemberInput
              organization_id={organization_id}
              source="sender_id"
            />
            <ProjectInput
              source="project_id"
              organization_id={organization_id}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
