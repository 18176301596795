import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import ButtonBaseDemo from "./Components/ImageButton"

export function Dashboard() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        mt: 0,
        display: "flex",
        alignItems: "center",
        // height: "100vh",
        width: "100%",
        backgroundSize: "cover",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backgroundBlendMode: "multiply",
        backgroundImage:
          "url(https://unsplash.com/photos/PqRvLsjD_TU/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjYyNTcxODAw&force=true&w=1024)",
      })}
    >
      <Container
        sx={{
          mt: 8,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "white",
            display: "flex",
            alignContent: "center",
            fontSize: "clamp(2.5rem, 8vw, 3.5rem)",
          }}
        >
          Welcome to&nbsp;
          <img
            src="/images/tooltribe.webp"
            alt="TOOLTRIBE"
            style={{ width: 250 }}
          />
        </Typography>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // mt: { xs: 14, sm: 20 },
            mb: { xs: 8, sm: 12 },
            p: { xs: 2, sm: 4 },
          }}
        >
          <Stack spacing={2}>
            <Typography textAlign="center" color="text.secondary">
              Scan this code to download the mobile app and start adding tools.
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignSelf="center"
              spacing={1}
              // useFlexGap
            >
              <img
                src="./images/qr-code.svg"
                alt="QR Code"
                style={{ width: 200 }}
              />
            </Stack>
            <Typography
              textAlign="center"
              color="text.secondary"
              sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
            >
              Or watch these tutorials to get started:
            </Typography>
            {/* <Link href="https://www.youtube.com/watch?v=SDvmUDwlgJA">
              Add Tools
            </Link>
            <Link
              href="https://www.youtube.com/watch?v=O0EhjRPjb6k"
              target="_blank"
              rel="noreferrer"
            >
              Add Crew
            </Link>
            <Link
              href="https://www.youtube.com/watch?v=mp2sXxAzuvM"
              target="_blank"
              rel="noreferrer"
            >
              Add Projects / Locations
            </Link>
            <Link
              href="https://www.youtube.com/watch?v=i1Nh5owh9SI"
              target="_blank"
              rel="noreferrer"
            >
              Transfer Tools
            </Link> */}
            <ButtonBaseDemo />
          </Stack>
        </Card>
      </Container>
    </Box>
  )
}
