import { FunctionField } from "react-admin"

export function IntervalField(props) {
  return (
    <FunctionField
      {...props}
      render={(record, source?: string) => {
        if (!record[source] || record[source] === "00:00:00") return ""
        return (record[source] as string).replace("mons", "months")
      }}
    />
  )
}
