import { useMemo } from "react"
import {
  AutocompleteInput,
  DateTimeInput,
  FileField,
  FileInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { CrewMemberInput } from "../../Tool/CrewMemberInput"

export const ServiceRecordForm = () => {
  const [organization_id] = useOrganizationId()
  const now = useMemo(() => new Date(), [])
  return (
    <SimpleForm defaultValues={{ organization_id }}>
      <ReferenceInput
        key="tool"
        reference="tools"
        source="tool_id"
        filter={{
          organization_id,
          "deleted_at@is": "NULL",
          "tool_photo@not.is": null,
          // "inactive_status@not.in": `(${InactiveStatus.Consumed},${InactiveStatus.Lost},${InactiveStatus.Stolen},${InactiveStatus.Destroyed})`,
        }}
      >
        <AutocompleteInput
          fullWidth
          source="display_name"
          optionText="display_name"
          filterToQuery={(query) => ({
            "search_terms@fts": query,
          })}
        />
      </ReferenceInput>
      <TextInput source="notes" validate={required()} multiline rows={3} />
      <TextInput source="cost" />
      <FileInput source="attachment" multiple={false}>
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
      <CrewMemberInput
        source="crew_member_id"
        organization_id={organization_id}
        showAutoToolroom={false}
      />
      <DateTimeInput source="completed_at" defaultValue={now} />
    </SimpleForm>
  )
}
