import jsonExport from "jsonexport/dist"
import { downloadCSV } from "react-admin"

export async function projectsExporter(
  projects: any[],
  _: any,
  dataProvider: any
) {
  const projectsForExport = await Promise.all(
    projects.map(async (project) => {
      const {
        project_id,
        // project_name,
        organization_id,
        created_at,
        updated_at,
        deleted_at,
        created_by_user_id,
        position_latitude,
        position_longitude,
        position_name,
        client_id,
        project_cap_percentage,
        project_discount_percentage,
        small_tools_value,
        small_tools_cap_percentage,
        id,
        ...projectForExport
      } = project
      const { data: client = null } = client_id
        ? await dataProvider.getOne("clients", {
            id: client_id,
          })
        : {}

      projectForExport.client = client ? client.client_name : ""
      return projectForExport
    })
  )

  const csv = await jsonExport(projectsForExport, {
    headers: ["project_name", "client"], // order fields in the export
  })
  downloadCSV(csv, "projects")
}
