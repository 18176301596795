import jsonExport from "jsonexport/dist"
import { downloadCSV } from "react-admin"
import { prettyPhoneFromPhoneNumber } from "../prettyPhoneFromPhoneNumber"
import { roles } from "../roles"

export async function crewMembersExporter(crewMembers: any[]) {
  const crewMembersForExport = crewMembers.map((crewMember) => {
    const {
      organization_id,
      created_at,
      phone_number_verified,
      phone_invite_sent,
      user_id,
      created_by_user,
      prefer_sms,
      profile_picture_url,
      updated_at,
      deleted_at,
      _project_id,
      hidden_tool_columns,
      hidden_transfers_by_project_columns,
      initials,
      display_name,
      crew_member_id,
      id,
      role,
      phone_number,
      is_deleted,
      ...crewMemberForExport
    } = crewMember
    crewMemberForExport.role = roles.find(({ id }) => id === role)?.name
    crewMemberForExport.phone = prettyPhoneFromPhoneNumber({ phone_number })
    return crewMemberForExport
  })
  const csv = await jsonExport(crewMembersForExport, {
    headers: ["name", "phone", "email", "role"], // order fields in the export
  })
  downloadCSV(csv, "crewMembers")
}
