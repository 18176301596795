import { useDisclosure } from "@dwarvesf/react-hooks"
import { Dialog, DialogTitle } from "@mui/material"

import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined"
import { Show } from "@react-admin/ra-rbac"
import { useMemo } from "react"
import {
  Button,
  DateField,
  DateTimeInput,
  EditButton,
  FileField,
  FileInput,
  ReferenceField,
  required,
  ResourceContextProvider,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  TopToolbar,
  useCreate,
  useCreatePath,
  useRecordContext,
} from "react-admin"
import { useNavigate } from "react-router-dom"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { ServiceIntervalField } from "../../Components/ServiceIntervalField"
import { CrewMemberInput } from "../../Tool/CrewMemberInput"

function CompleteServiceButton(props) {
  const createPath = useCreatePath()
  const navigate = useNavigate()
  const [organization_id] = useOrganizationId()
  const { id } = useRecordContext(props)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [create] = useCreate()

  const handleCreate = async (record) => {
    const { notes, cost, attachment, crew_member_id, completed_at, tool_id } =
      record
    const data = {
      tool_id,
      tool_service_id: id,
      organization_id,
      notes,
      cost,
      attachment,
      crew_member_id,
      completed_at,
    }
    create(
      "service_records",
      { data },
      {
        onSuccess: () =>
          navigate(createPath({ resource: "tool_services", type: "list" })),
      }
    )
  }
  const now = useMemo(() => new Date(), [])

  return (
    <>
      <Button
        label="Complete Service"
        onClick={onOpen}
        startIcon={<ScheduleOutlinedIcon />}
      />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Complete Service</DialogTitle>
        <ResourceContextProvider value="service_records">
          <SimpleForm onSubmit={handleCreate}>
            <TextInput
              source="notes"
              validate={required()}
              multiline
              rows={3}
            />
            <TextInput source="cost" />
            <FileInput source="attachment" multiple={false}>
              <FileField source="src" title="title" target="_blank" />
            </FileInput>
            <CrewMemberInput
              source="crew_member_id"
              organization_id={organization_id}
              showAutoToolroom={false}
            />
            <DateTimeInput source="completed_at" defaultValue={now} />
          </SimpleForm>
        </ResourceContextProvider>
      </Dialog>
    </>
  )
}

export const ToolServiceShow = () => (
  <Show
    actions={
      <TopToolbar>
        <CompleteServiceButton />
        <EditButton />
      </TopToolbar>
    }
  >
    <SimpleShowLayout>
      <ReferenceField source="tool_id" reference="tools" />
      <ReferenceField
        source="service_template_id"
        reference="service_templates"
      />
      <ReferenceField source="crew_member_id" reference="crew_members" />
      <DateField source="due_date" />
      <ReferenceField
        source="service_template_id"
        reference="service_templates"
        label="Recurring" // TODO: i18n
      >
        <ServiceIntervalField source="service_interval" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)
