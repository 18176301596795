import UploadIcon from "@mui/icons-material/Upload"
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import {
  BooleanField,
  Button,
  Datagrid,
  ImageField,
  List,
  TextField,
  TextInput,
  useCreate,
  useListContext,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { useParams } from "react-router-dom"
import { useOrganizationId } from "../../hooks/useOrganizationId"

const ProcoreUserImportButton = () => {
  const { resource, data, selectedIds } = useListContext()
  const [organization_id] = useOrganizationId()
  const notify = useNotify()
  const unselectAll = useUnselectAll(resource)

  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )
  const [create, { isLoading }] = useCreate("users")
  return (
    <Button
      label="ra.action.procore.users.import.selected"
      disabled={isLoading}
      onClick={async () => {
        await Promise.all(
          selectedRecords.map((record) => {
            const countryCode = record.country_code ?? "US"
            const phone_number =
              record.mobile_phone != null &&
              isPossiblePhoneNumber(record.mobile_phone, countryCode)
                ? parsePhoneNumber(record.mobile_phone, countryCode).format(
                    "E.164"
                  )
                : null

            return create(
              "crew_members",
              {
                data: {
                  organization_id,
                  name: record.name,
                  email: record.email_address,
                  employee_id: record.employee_id,
                  phone_number,
                  profile_picture_url: record.avatar,

                  // procore_user_id: record.id,
                  // procore_company_id: record.company.id,
                },
              },
              {
                onSuccess: () => {},
              }
            )
          })
        )
        notify(`ra.action.procore.users.import.notification`, {
          type: "success",
          messageArgs: { smart_count: selectedRecords.length },
        })
        unselectAll()
      }}
    >
      <UploadIcon />
    </Button>
  )
}
// GET /rest/v1.0/companies/{company_id}/users
const ProcoreUsersFilters = [
  <TextInput
    key="search"
    source="search"
    alwaysOn
    label="resources.procore/users.filters.search"
  />,
  // Doesn't currently work but we can add when procore supports it
  // <NullableBooleanInput
  //   key="is_employee"
  //   source="is_employee"
  //   alwaysOn
  //   label="resources.procore/users.filters.is_employee"
  // />,
]
export function ProcoreUsersList() {
  const { company_id } = useParams()

  return (
    <List
      exporter={false}
      resource="procore/users"
      filter={{ company_id }}
      filters={ProcoreUsersFilters}
    >
      <Datagrid
        resource="procore/users"
        // rowClick="edit"
        bulkActionButtons={<ProcoreUserImportButton />}
      >
        <ImageField source="avatar" />
        <TextField source="employee_id" />
        <TextField source="name" />
        <TextField source="email_address" />
        <TextField source="mobile_phone" />
        <BooleanField source="is_employee" />
      </Datagrid>
    </List>
  )
}
