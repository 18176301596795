import jsonExport from "jsonexport/dist"
import { downloadCSV, fetchRelatedRecords } from "react-admin"

export async function toolServicesExporter(
  toolServices: any[],
  _: any,
  dataProvider: any
) {
  const serviceTemplates = await fetchRelatedRecords(dataProvider)(
    toolServices,
    "service_template_id",
    "service_templates"
  )

  const toolServicesForExport = await Promise.all(
    toolServices.map(async (toolService) => {
      const { tools, due_date, service_template_id, crew_members } = toolService

      // Item ID
      // Item
      // Service Type
      // Assigned To
      // Due Date
      // Recurring
      debugger
      return {
        asset_tag: tools.asset_tag,
        name: tools.full_display_name,
        service_type: serviceTemplates[service_template_id].name,
        assigned_to: crew_members?.name ?? "",
        due_date,
        recurring: serviceTemplates[service_template_id].is_recurring
          ? serviceTemplates[service_template_id].service_interval.replace(
              "mons",
              "months"
            )
          : "",
      }
    })
  )

  const csv = await jsonExport(toolServicesForExport, {
    headers: [
      "asset_tag",
      "name",
      "service_type",
      "assigned_to",
      "due_date",
      "recurring",
    ], // order fields in the export
  })
  downloadCSV(csv, "toolServices")
}
