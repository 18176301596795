import Typography from "@mui/material/Typography"
import { AppBar, TitlePortal } from "react-admin"
import { useOrganization } from "../../../hooks/useOrganizationId"
import { Logo } from "./Logo"
import { MyUserMenu } from "./MyUserMenu"

// We need to tooltribe black and white logo on the app bar
export function MyAppBar(props) {
  const { data: organization, isLoading } = useOrganization()
  if (isLoading) return null
  return (
    <AppBar
      {...props}
      userMenu={<MyUserMenu />}
      sx={{ backgroundColor: "black", color: "white", borderColor: "black" }}
    >
      <Logo />
      <TitlePortal />
      {organization.logo?.src ? (
        <img
          src={organization.logo.src}
          alt={organization.name}
          style={{ height: 32 }}
        />
      ) : (
        <Typography>{organization.name}</Typography>
      )}
    </AppBar>
  )
}
