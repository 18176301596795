import { Show, SimpleShowLayout } from "@react-admin/ra-rbac"
import {
  BooleanField,
  DateField,
  ListContextProvider,
  NumberField,
  ReferenceField,
  TextField,
  useGetList,
  useList,
  useRecordContext,
} from "react-admin"
import { ToolDatagrid } from "../Tool/ToolList"
import { InactiveStatus } from "../Tool/inactiveStatuses"
import { ToolState } from "../Tool/toolStates"
export const ReorderShow = () => {
  return (
    <Show>
      <InnerShow />
    </Show>
  )
}
const InnerShow = () => {
  const record = useRecordContext()
  const { data, isLoading } = useGetList("tools", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "updated_at", order: "DESC" },
    filter: {
      "deleted_at@is": null,
      organization_id: record?.organization_id,
      state: ToolState.Inactive,
      inactive_status: InactiveStatus.Consumed,
      group_id: record?.group_id,
    },
  })
  const listContext = useList({ data, isLoading })
  return (
    <SimpleShowLayout>
      <ReferenceField source="group_id" reference="groups">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="project_id" reference="projects" />
      <NumberField source="quantity" />
      <NumberField source="reorder_point" />
      <BooleanField source="needs_reorder" />
      <NumberField source="reordered_quantity" />
      <DateField source="reordered_at" />
      <ListContextProvider value={listContext}>
        <ToolDatagrid
          sort={{ field: "updated_at", order: "DESC" }}
          bulkActionButtons={false}
          rowClick="show"
        />
      </ListContextProvider>
    </SimpleShowLayout>
  )
}
