import { Datagrid, List } from "@react-admin/ra-rbac"
import { DateField, ReferenceField, TextField } from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { ThumbnailField } from "../Components/ThumbnailField"

export const ToolScanList = () => {
  const [organization_id] = useOrganizationId()
  return (
    <List
      filter={{ "deleted_at@is": null, organization_id }}
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <ThumbnailField source="scan_photo" />
        <TextField source="serial_number" />
        <ReferenceField source="organization_id" reference="organizations">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" showTime />
      </Datagrid>
    </List>
  )
}
