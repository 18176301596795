import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceInput,
  TextField,
} from "react-admin"

import { DateTime } from "luxon"
import { useMemo } from "react"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import {
  projectReportExporter,
  totalDollarsFromDollarsAndQuantity,
} from "./projectReportExporter"

const ProjectReportFilters = (organization_id) => [
  <ReferenceInput
    key="project"
    reference="projects"
    source="project_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{ organization_id, "deleted_at@is": null }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="project_name"
      optionText="project_name"
      filterToQuery={(query) => ({ "project_name@ilike": query })}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="category"
    reference="org_categories"
    source="tools.category"
    filter={{ organization_id }}
    sort={{ field: "tool_count", order: "DESC" }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="name"
      optionText="name"
      filterToQuery={(query) => ({ "name@ilike": query })}
    />
  </ReferenceInput>,
]

export const ProjectReportList = () => {
  const [organization_id] = useOrganizationId()
  const filters = useMemo(
    () => ProjectReportFilters(organization_id),
    [organization_id]
  )
  return (
    <List
      exporter={projectReportExporter}
      sort={{ field: "end_date", order: "DESC" }}
      filter={{
        // We have to use tool not tools here, ditto for project because it's a function work around for the view
        "select@":
          "*,projects:project(project_name),tools:tool(full_display_name,value)",
        organization_id,
        "tools.organization_id": organization_id,
        "projects.organization_id": organization_id,
      }}
      filters={filters}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="projects.project_name" />
        <TextField source="tools.full_display_name" />
        <TextField source="quantity" />
        <DateField showTime source="start_date" />
        <DateField showTime source="end_date" />
        <FunctionField
          label="Duration"
          render={({ end_date, start_date }) => {
            const startDate = DateTime.fromISO(start_date)
            const endDate = DateTime.fromISO(end_date)
            const duration = endDate.diff(startDate, "hours")
            const durationUnitized = duration.shiftTo(
              "days",
              "hours",
              "minutes"
            )

            return durationUnitized
              .set({ minutes: Math.round(durationUnitized.minutes) })
              .toHuman()
          }}
        />
        <FunctionField
          source="tools.value"
          render={({ tools, quantity }) => {
            if (tools == null || tools?.value == null) return ""
            return totalDollarsFromDollarsAndQuantity(tools.value, quantity)
          }}
        />
      </Datagrid>
    </List>
  )
}
