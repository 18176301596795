import { FunctionField } from "react-admin"

export function ServiceIntervalField(props) {
  return (
    <FunctionField
      {...props}
      render={(record, source?: string) => {
        if (!record[source]) return ""
        return `${record?.is_recurring ? "Every" : "After"} ${(
          record[source] as string
        ).replace("mons", "months")}`
      }}
    />
  )
}
