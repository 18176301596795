import { BulkUpdateFormButton } from "@react-admin/ra-form-layout"
import { Datagrid, List } from "@react-admin/ra-rbac"
import {
  CreateButton,
  EmailField,
  ExportButton,
  FilterButton,
  FunctionField,
  Pagination,
  ReferenceField,
  SelectField,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin"
import { NIL } from "uuid"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { BulkDeleteAndTransferButton } from "../Components/BulkDeleteAndTransferButton"
import { prettyPhoneFromPhoneNumber } from "../prettyPhoneFromPhoneNumber"
import { ProcoreImportButton } from "../Procore/ProcoreImportButtons"
import { roles } from "../roles"
import { ProjectInput } from "../Tool/ProjectInput"
import { RoleInput } from "./CrewMemberForm"
import { crewMembersExporter } from "./crewMembersExport"
const CrewMemberFilters = [
  <TextInput key="name" label="Search" source="name@ilike" alwaysOn />,
]

const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <ExportButton />
      <ProcoreImportButton resource="users" />
    </TopToolbar>
  )
}

export const CrewMemberList = () => {
  const [organization_id] = useOrganizationId()

  return (
    <List
      filters={CrewMemberFilters}
      actions={<ListActions />}
      exporter={crewMembersExporter}
      filter={{
        "crew_member_id@neq": NIL,
        "role@neq": "support",
        "deleted_at@is": "null",
        organization_id,
      }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={
          <TopToolbar>
            <BulkUpdateFormButton
            // label="ra.action.transfer"
            // icon={<ArrowForwardIcon />}
            >
              <SimpleForm>
                <RoleInput />
                <ProjectInput
                  source="default_project_id"
                  organization_id={organization_id}
                />
              </SimpleForm>
            </BulkUpdateFormButton>
            <BulkDeleteAndTransferButton source="sender_id" />
          </TopToolbar>
        }
      >
        {/* <ImageField source="profile_picture_url" /> */}
        <TextField source="name" />
        <FunctionField
          source="phone_number"
          render={prettyPhoneFromPhoneNumber}
        />
        <EmailField source="email" />
        <SelectField source="role" choices={roles} />
        <ReferenceField source="default_project_id" reference="projects" />
      </Datagrid>
    </List>
  )
}
