import jsonExport from "jsonexport/dist"
import { downloadCSV } from "react-admin"
export async function projectReportExporter(projectReport: any[]) {
  const projectReportForExport = await Promise.all(
    projectReport.map((projectReportItem) => {
      const {
        id,
        tool_id,
        project_id,
        organization_id,
        start_date,
        end_date,
        tools,
        projects,
        ...projectReportItemForExport
      } = projectReportItem

      projectReportItemForExport.project = projects ? projects.project_name : ""
      projectReportItemForExport.tool = tools ? tools.full_display_name : ""

      projectReportItemForExport.start_date = new Date(
        start_date
      ).toLocaleString()
      projectReportItemForExport.end_date = new Date(end_date).toLocaleString()
      // projectReportItemForExport.duration = new Date(end_date).getTime() - new Date(start_date).getTime()
      if (tools?.value != null) {
        projectReportItemForExport.value = totalDollarsFromDollarsAndQuantity(
          tools.value,
          projectReportItem.quantity
        )
      }
      return projectReportItemForExport
    })
  )
  const csv = await jsonExport(projectReportForExport, {
    headers: ["project", "tool", "start_date", "end_date", "duration"], // order fields in the export
  })
  downloadCSV(csv, "project_report")
}

export function totalDollarsFromDollarsAndQuantity(
  value: string,
  quantity: number
) {
  return `$${(floatFromDollars(value) * quantity).toFixed(2)}`
}

function floatFromDollars(dollars: string) {
  return parseFloat(dollars.replace("$", ""))
}
