import { Show, SimpleShowLayout, useCanAccess } from "@react-admin/ra-rbac"
import {
  EditButton,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin"
import { NIL } from "uuid"
import { ThumbnailField } from "../Components/ThumbnailField"

function ActionButtonsMaybeEdit() {
  const record = useRecordContext()
  const canEdit = record?.organization_id !== NIL
  const allowedToEdit = useCanAccess({ resource: "categories", action: "edit" })
  return <TopToolbar>{canEdit && allowedToEdit && <EditButton />}</TopToolbar>
}

export const CategoryShow = () => {
  return (
    <Show actions={<ActionButtonsMaybeEdit />}>
      <SimpleShowLayout>
        <ThumbnailField source="image_url" />
        <TextField source="name" />
      </SimpleShowLayout>
    </Show>
  )
}
