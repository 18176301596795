import { SimpleForm } from "@react-admin/ra-rbac"
import { useMemo } from "react"
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  useRecordContext,
} from "react-admin"
import { useWatch } from "react-hook-form"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { PercentInput } from "../Components/PercentInput"

export function ProjectForm(props) {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm defaultValues={{ organization_id }} {...props}>
      <InnerForm />
    </SimpleForm>
  )
}
function InnerForm() {
  const [organization_id] = useOrganizationId()
  const context = useRecordContext()
  const project_id = context?.id
  const project_type = useWatch({ name: "project_type" })
  const toolroom_manager_id = useWatch({ name: "toolroom_manager_id" })
  const first_alternate_toolroom_manager_id = useWatch({
    name: "first_alternate_toolroom_manager_id",
  })
  const second_alternate_toolroom_manager_id = useWatch({
    name: "second_alternate_toolroom_manager_id",
  })
  const showToolroomManager = project_type === "toolroom"
  const showFirstAlternateToolroomManager =
    showToolroomManager && toolroom_manager_id
  const showSecondAlternateToolroomManager =
    showFirstAlternateToolroomManager && first_alternate_toolroom_manager_id
  const showThirdAlternateToolroomManager =
    showSecondAlternateToolroomManager && second_alternate_toolroom_manager_id
  const filter = useMemo(() => {
    return {
      organization_id,
      "deleted_at@is": "NULL",
      "role@neq": "support",
    }
  }, [organization_id])
  return (
    <>
      <TextInput source="project_name" validate={required()} />
      <SelectInput
        source="project_type"
        defaultValue={"project"}
        validate={required()}
        choices={[
          { id: "project", name: "Project" },
          { id: "toolroom", name: "Toolroom" },
        ]}
      />
      {showToolroomManager && (
        <ReferenceInput
          reference="crew_members"
          source="toolroom_manager_id"
          filter={filter}
        >
          <AutocompleteInput
            source="name"
            optionText="name"
            filterToQuery={(query) => ({ "name@ilike": query })}
          />
        </ReferenceInput>
      )}
      {showFirstAlternateToolroomManager && (
        <ReferenceInput
          reference="crew_members"
          source="first_alternate_toolroom_manager_id"
          filter={filter}
        >
          <AutocompleteInput
            source="name"
            optionText="name"
            filterToQuery={(query) => ({ "name@ilike": query })}
          />
        </ReferenceInput>
      )}
      {showSecondAlternateToolroomManager && (
        <ReferenceInput
          reference="crew_members"
          source="second_alternate_toolroom_manager_id"
          filter={filter}
        >
          <AutocompleteInput
            source="name"
            optionText="name"
            filterToQuery={(query) => ({ "name@ilike": query })}
          />
        </ReferenceInput>
      )}
      {showThirdAlternateToolroomManager && (
        <ReferenceInput
          reference="crew_members"
          source="third_alternate_toolroom_manager_id"
          filter={filter}
        >
          <AutocompleteInput
            source="name"
            optionText="name"
            filterToQuery={(query) => ({ "name@ilike": query })}
          />
        </ReferenceInput>
      )}
      <PercentInput source="project_cap_percentage" />
      <PercentInput source="project_discount_percentage" />
      {project_id && (
        <ReferenceInput
          reference="cost_codes"
          source="default_cost_code_id"
          filter={{ organization_id, project_id, "deleted_at@is": "NULL" }}
        >
          <AutocompleteInput
            source="cost_code_name"
            optionText="cost_code_name"
            filterToQuery={(query) => ({ "cost_code_name@ilike": query })}
          />
        </ReferenceInput>
      )}
      <TextInput source="small_tools_value" />
      <PercentInput source="small_tools_cap_percentage" />
    </>
  )
}
