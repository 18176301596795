import jsonExport from "jsonexport/dist"
import { downloadCSV } from "react-admin"

export async function CategoriesExporter(
  categories: any[],
  _: any,
  dataProvider: any
) {
  const categoriesForExport = await Promise.all(
    categories.map(async (category) => {
      const { name, image_url } = category
      return { name, image_url: image_url?.src }
    })
  )

  const csv = await jsonExport(categoriesForExport, {
    headers: ["name", "image_url"], // order fields in the export
  })
  downloadCSV(csv, "Categories")
}
