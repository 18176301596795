import { Datagrid, List } from "@react-admin/ra-rbac"
import { SelectField, TextField, TextInput, useGetIdentity } from "react-admin"
import { NIL } from "uuid"

const referralSources = [
  { id: "recommended", label: "Referral" },
  {
    id: "search",
    label: "Search (Google, Bing, etc.)",
  },
  {
    id: "ad",
    label: "Google Ad",
  },
  {
    id: "social",
    label: "Social (Facebook, Twitter, etc.)",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "capterra",
    label: "Capterra",
  },
  {
    id: "g2",
    label: "G2",
  },
  {
    id: "softwareadvice",
    label: "Software Advice",
  },
  {
    id: "getapp",
    label: "GetApp",
  },
]

export function OrganizationList() {
  // const [organizationId] = useOrganizationId()
  // const redirect = useRedirect()
  // redirect("edit", "organizations", organizationId)
  const { identity } = useGetIdentity()
  const role = identity?.role

  return (
    <List
      filters={[
        <TextInput
          key="name"
          label="Search"
          // We are doing this rather than wfts so we can get partial matches on the final word
          source="name@ilike"
          alwaysOn
        />,
      ]}
      filter={{
        "deleted_at@is": null,
        "organization_id@neq": NIL,
      }}
      perPage={25}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        {role === "support" && <TextField source="company_size" />}
        {role === "support" && (
          <SelectField source="referral_source" choices={referralSources} />
        )}
      </Datagrid>
    </List>
  )
}
