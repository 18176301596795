import jsonExport from "jsonexport/dist"
import { downloadCSV, fetchRelatedRecords } from "react-admin"
import { NIL } from "uuid"
export async function consumablesReportExporter(
  consumablesReport: any[],
  _: any,
  dataProvider: any
) {
  const projects = await fetchRelatedRecords(dataProvider)(
    consumablesReport,
    "project_id",
    "projects"
  )

  const tools = await fetchRelatedRecords(dataProvider)(
    consumablesReport,
    "tool_id",
    "tools"
  )
  const organizations = await fetchRelatedRecords(dataProvider)(
    consumablesReport,
    "organization_id",
    "organizations"
  )
  const consumablesReportForExport = await Promise.all(
    consumablesReport.map((consumablesReportItem) => {
      const {
        id,
        tool_id,
        project_id,
        organization_id,
        consumed_date,
        ...consumablesReportItemForExport
      } = consumablesReportItem

      // Use the custom organization toolroom name in place of project and crew when assigned to toolroom
      const project =
        project_id !== NIL
          ? projects[project_id]
          : {
              project_name: organizations[organization_id].toolroom_name,
            }
      const tool = tools[tool_id]
      consumablesReportItemForExport.project = project
        ? project.project_name
        : ""
      consumablesReportItemForExport.tool = tool ? tool.display_name : ""

      consumablesReportItemForExport.consumed_date = new Date(
        consumed_date
      ).toLocaleString()
      return consumablesReportItemForExport
    })
  )
  const csv = await jsonExport(consumablesReportForExport, {
    headers: ["project", "tool", "consumed_date", "value"], // order fields in the export
  })
  downloadCSV(csv, "consumables_report")
}
