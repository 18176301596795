import { forwardRef } from "react"
import {
  Datagrid,
  useGetIdentity,
  useGetList,
  usePermissions,
} from "react-admin"
import { NIL } from "uuid"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { OrganizationButton } from "./OrganizationButton"

export const OrganizationsMenu = forwardRef<HTMLAnchorElement>((props, ref) => {
  const { identity } = useGetIdentity()
  const { refetch } = usePermissions()
  const { organizations } = identity
  const [, setOrganizationId] = useOrganizationId()
  const { data, isLoading } = useGetList("organizations", {
    pagination: { perPage: 500, page: 1 },
    filter: {
      "deleted_at@is": null,
      "organization_id@in": organizations,
      "organization_id@neq": NIL,
    },
    sort: { field: "name", order: "ASC" },
  })
  if (isLoading) return null
  return (
    // <ListContextProvider value={listContext}>
    <Datagrid
      data={data}
      header={() => null}
      isRowSelectable={() => false}
      sx={{ "& .MuiTableCell-paddingCheckbox": { display: "none" } }}
    >
      <OrganizationButton
        setOrganizationId={(organizationId) => {
          setOrganizationId(organizationId)
          refetch()
        }}
      />
    </Datagrid>
    // </ListContextProvider>
  )
})
