import UploadIcon from "@mui/icons-material/Upload"
import {
  Button,
  Datagrid,
  List,
  TextField,
  TextInput,
  useCreate,
  useListContext,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { useParams } from "react-router-dom"
import { useOrganizationId } from "../../hooks/useOrganizationId"

const ProcoreProjectImportButton = () => {
  const { resource, data, selectedIds } = useListContext()
  const [organization_id] = useOrganizationId()
  const notify = useNotify()
  const unselectAll = useUnselectAll(resource)

  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )
  const [create, { isLoading }] = useCreate("projects")
  return (
    <Button
      label="ra.action.procore.projects.import.selected"
      disabled={isLoading}
      onClick={async () => {
        await Promise.all(
          selectedRecords.map((record) =>
            create(
              "projects",
              {
                data: {
                  organization_id,
                  project_name: record.display_name,
                  procore_project_id: record.id,
                  procore_company_id: record.company.id,
                },
              },
              {
                onSuccess: () => {},
              }
            )
          )
        )
        notify(`ra.action.procore.projects.import.notification`, {
          type: "success",
          messageArgs: { smart_count: selectedRecords.length },
        })
        unselectAll()
      }}
    >
      <UploadIcon />
    </Button>
  )
}

const ProcoreProjectFilters = [
  <TextInput
    key="project_number"
    label="Project Number"
    source="project_number"
    alwaysOn
  />,
]

export const ProcoreProjectList = () => {
  const { company_id } = useParams()

  return (
    <List
      exporter={false}
      resource="procore/projects"
      filter={{ company_id, by_status: "Active" }}
      filters={ProcoreProjectFilters}
    >
      <Datagrid
        resource="procore/projects"
        // rowClick="edit"
        bulkActionButtons={<ProcoreProjectImportButton />}
      >
        <TextField source="display_name" />
      </Datagrid>
    </List>
  )
}
