import { Edit } from "@react-admin/ra-rbac"
import { SaveButton, Toolbar } from "react-admin"
import { DeleteAndTransferButton } from "../Components/DeleteAndTransferButton"
import { CrewMemberForm } from "./CrewMemberForm"

const CrewMemberToolbar = (props) => (
  <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
    <SaveButton />
    <DeleteAndTransferButton source="sender_id" />
  </Toolbar>
)
export const CrewMemberEdit = () => (
  <Edit>
    <CrewMemberForm toolbar={<CrewMemberToolbar />} />
  </Edit>
)
