import { Logout, UserMenu } from "react-admin"
import { OrganizationsMenu } from "./OrganizationsMenu"
import { QRCodeMenu } from "./QRCodeMenu"
import { SubscriptionMenu } from "./SubscriptionMenu"

export function MyUserMenu(props) {
  return (
    <UserMenu {...props}>
      <OrganizationsMenu />
      <SubscriptionMenu />
      <QRCodeMenu />
      <Logout />
    </UserMenu>
  )
}
