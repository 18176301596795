import { Create } from "react-admin"
import { ToolServiceForm } from "./ToolServiceForm"

export function ToolServiceCreate() {
  return (
    <Create>
      <ToolServiceForm />
    </Create>
  )
}
