import { required } from "ra-core"
import { TextInput } from "react-admin"
import { useSearchParams } from "react-router-dom"
import { SupabaseAuthForm } from "./SupabaseAuthForm"

export const LoginForm = ({
  redirectTo,
  className,
}: {
  redirectTo?: string
  className?: string
}) => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get("email")

  return (
    <SupabaseAuthForm
      redirectTo={redirectTo}
      className={className}
      submitLabel="Send Login Link"
    >
      <p>
        Enter your email below and we'll send you a link to complete the login
        process
      </p>
      <TextInput
        variant="outlined"
        autoFocus
        defaultValue={email}
        source="email"
        label="Email"
        validate={required()}
        fullWidth
        parse={(value) => value.trim().toLowerCase()}
      />
      <p>
        Don’t have an account?{" "}
        <a href="https://tooltribe.com/pricing">Sign up</a> to get started.
      </p>
    </SupabaseAuthForm>
  )
}
