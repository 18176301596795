import { useGetIdentity } from "react-admin"

export const roles = [
  { id: "crew", name: "Crew Member" },
  // { id: "toolroom", name: "Toolroom Manager" },
  { id: "admin", name: "Organization Admin" },
  { id: "observer", name: "Observer" },
]

export function useRoles() {
  const { identity, isLoading } = useGetIdentity()
  if (isLoading) {
    return []
  }
  if (["admin", "support"].includes(identity?.role)) {
    return roles
  }
  return roles.filter((role) => role.id !== "admin")
}
