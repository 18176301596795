import { Datagrid, List } from "@react-admin/ra-rbac"
import { Pagination, TextField, TextInput, WrapperField } from "react-admin"
import { NIL } from "uuid"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { ThumbnailField } from "../Components/ThumbnailField"
import { CategoriesExporter } from "./categoriesExporter"

const CategoryFilters = [
  <TextInput key="name" label="Search" source="name@ilike" alwaysOn />,
]

export const CategoryList = () => {
  const [organization_id] = useOrganizationId()
  return (
    <List
      filters={CategoryFilters}
      exporter={CategoriesExporter}
      sort={{ field: "name", order: "ASC" }}
      filter={{
        "deleted_at@is": null,
        "organization_id@in": [NIL, organization_id],
      }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid
        rowClick="show"
        isRowSelectable={(record) => record.organization_id !== NIL}
      >
        <WrapperField source="name">
          <TextField source="name" />
          <ThumbnailField source="image_url" src="src" />
        </WrapperField>
      </Datagrid>
    </List>
  )
}
