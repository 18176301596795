import { Edit } from "@react-admin/ra-rbac"
import { ToolServiceForm } from "./ToolServiceForm"

export function ToolServiceEdit() {
  return (
    <Edit mutationMode="pessimistic">
      <ToolServiceForm />
    </Edit>
  )
}
