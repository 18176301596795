import { Datagrid, List } from "@react-admin/ra-rbac"
import { Pagination, TextField, TextInput } from "react-admin"
import { NIL } from "uuid"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { BrandsExporter } from "./brandsExporter"

const BrandFilters = [
  <TextInput key="name" label="Search" source="name@ilike" alwaysOn />,
]

export const BrandList = () => {
  const [organization_id] = useOrganizationId()
  return (
    <List
      filters={BrandFilters}
      exporter={BrandsExporter}
      sort={{ field: "name", order: "ASC" }}
      filter={{
        "deleted_at@is": null,
        "organization_id@in": [NIL, organization_id],
      }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid
        rowClick="show"
        isRowSelectable={(record) => record.organization_id !== NIL}
      >
        <TextField source="name" />
      </Datagrid>
    </List>
  )
}
