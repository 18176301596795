import { AppLocationContext } from "@react-admin/ra-navigation"
import { useEffect } from "react"
import {
  LoadingPage,
  Layout as RaLayout,
  useGetIdentity,
  useGetList,
  usePermissions,
  useRedirect,
} from "react-admin"
import { useSearchParams } from "react-router-dom"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { SignUp } from "../SignUp"
import { Menu } from "./Menu"
import { MyAppBar } from "./MyAppBar"

export function Layout(props) {
  const [organizationId] = useOrganizationId()
  const {
    identity,
    isLoading: isLoadingIdentity,
    refetch: refetchIdentity = () => {},
    error,
  } = useGetIdentity()
  const {
    permissions,
    isLoading: isLoadingPermissions,
    refetch: refetchPermissions,
  } = usePermissions()
  useEffect(() => {
    if (!organizationId || isLoadingIdentity) return
    refetchIdentity()
  }, [error, isLoadingIdentity, organizationId, refetchIdentity])

  useEffect(() => {
    if (permissions === undefined && !isLoadingPermissions) {
      refetchPermissions()
    }
  }, [permissions, isLoadingPermissions, refetchPermissions])
  const [params] = useSearchParams()
  const companyId = params.get("companyId")
  const projectId = params.get("projectId")

  if (companyId && projectId) {
    return <ProcoreRedirector companyId={companyId} projectId={projectId} />
  }
  if (isLoadingPermissions || isLoadingIdentity || !identity)
    return <LoadingPage />
  // This is a weird state that happens when the user is logged in but the identity is not yet loaded
  // if (!permissions) window.location.reload()
  if (identity.organizations.length === 0) return <SignUp />
  if (identity.redirectUrl) {
    window.location.replace(identity.redirectUrl)
    return null
  }
  return (
    <AppLocationContext>
      <RaLayout {...props} appBar={MyAppBar} menu={Menu} />
    </AppLocationContext>
  )
}

const ProcoreRedirector = ({ companyId, projectId }) => {
  const { data: projects, isLoading: isLoadingProjects } = useGetList(
    "projects",
    {
      filter: {
        procore_company_id: companyId,
        procore_project_id: projectId,
      },
      pagination: { page: 1, perPage: 1 },
    }
  )
  const redirect = useRedirect()
  const [, setOrganizationId] = useOrganizationId()
  useEffect(() => {
    if (
      !companyId ||
      !projectId ||
      isLoadingProjects ||
      !projects ||
      projects.length === 0
    )
      return
    const project = projects[0]
    if (!project) return
    setOrganizationId(project.organization_id)
    redirect(`/projects/${project.id}/show`)
  }, [
    companyId,
    isLoadingProjects,
    projectId,
    projects,
    redirect,
    setOrganizationId,
  ])
  return <LoadingPage />
}
