import { Box, DialogContent, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useRecordContext } from "ra-core"

import { SxProps } from "@mui/system"
import { fieldPropTypes } from "ra-ui-materialui/dist/cjs/field/types"
import { useCallback, useState } from "react"
import { sanitizeFieldRestProps } from "react-admin"
import { BootstrapDialog, BootstrapDialogTitle } from "./BootstrapDialog"

export const ThumbnailField = (
  props: ImageFieldProps & { width?: number; height?: number }
) => {
  const {
    className,
    emptyText,
    source,
    src = "src",
    title,
    width,
    height,
    ...rest
  } = props || {}
  const record = useRecordContext(props)
  const sourceValue = get(record, source)[src]
  const [open, setOpen] = useState(false)

  const onClose = useCallback(() => setOpen(false), [])

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeFieldRestProps(rest)} />
    )
  }

  if (Array.isArray(sourceValue)) {
    return (
      <Root className={className} {...sanitizeFieldRestProps(rest)}>
        <ul className={ThumbnailFieldClasses.list}>
          {sourceValue.map((file, index) => {
            const fileTitleValue = get(file, title) || title
            const srcValue = get(file, src) || title
            const url = new URL(srcValue.replace("/object/", "/render/image/"))
            if (width) url.searchParams.append("width", width.toString())
            if (height) url.searchParams.append("height", height.toString())
            const finalSrc = url.href
            return (
              <li key={index}>
                <img
                  alt={fileTitleValue}
                  title={fileTitleValue}
                  src={finalSrc}
                  className={ThumbnailFieldClasses.image}
                />
              </li>
            )
          })}
        </ul>
      </Root>
    )
  }

  const titleValue = get(record, title) || title
  const url = new URL(sourceValue.replace("/object/", "/render/image/"))
  if (width) url.searchParams.append("width", width.toString())
  if (height) url.searchParams.append("height", height.toString())
  const finalSrc = url.href
  return (
    <>
      <Root className={className} {...sanitizeFieldRestProps(rest)}>
        <img
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpen(true)
          }}
          title={titleValue}
          alt={titleValue}
          src={finalSrc}
          className={ThumbnailFieldClasses.image}
        />
        <BootstrapDialog
          // fullScreen
          fullWidth
          maxWidth="md"
          open={open}
          onClose={onClose}
          onClick={(e) => {
            setOpen(false)
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <BootstrapDialogTitle onClose={onClose}>
            {titleValue}
          </BootstrapDialogTitle>
          <DialogContent>
            <img
              title={titleValue}
              alt={titleValue}
              src={sourceValue}
              className={ThumbnailFieldClasses.fullImage}
              style={{ width: "100%" }}
            />
          </DialogContent>
        </BootstrapDialog>
      </Root>
    </>
  )
}

// What? TypeScript loses the displayName if we don't set it explicitly
ThumbnailField.displayName = "ThumbnailField"

ThumbnailField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string,
}
export interface DialogTitleProps {
  id?: string
  children?: React.ReactNode
  onClose: () => void
}

const PREFIX = "RaThumbnailField"

const ThumbnailFieldClasses = {
  list: `${PREFIX}-list`,
  image: `${PREFIX}-image`,
  fullImage: `${PREFIX}-full`,
}

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`& .${ThumbnailFieldClasses.list}`]: {
    display: "flex",
    listStyleType: "none",
  },
  [`& .${ThumbnailFieldClasses.image}`]: {
    margin: "0.25rem",
    width: 200,
    height: 100,
    objectFit: "contain",
  },
  [`& .${ThumbnailFieldClasses.fullImage}`]: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
})

interface ImageFieldProps {
  src?: string
  title?: string
  sx?: SxProps
  source: string
  emptyText?: string
  className?: string
}
