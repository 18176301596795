import { Edit } from "@react-admin/ra-rbac"
import { SaveButton, Toolbar } from "react-admin"
import { DeleteAndTransferButton } from "../Components/DeleteAndTransferButton"
import { ProjectForm } from "./ProjectForm"

const ProjectToolbar = (props) => (
  <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
    <SaveButton />
    <DeleteAndTransferButton />
  </Toolbar>
)
export const ProjectEdit = () => (
  <Edit>
    <ProjectForm toolbar={<ProjectToolbar />} />
  </Edit>
)
