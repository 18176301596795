import CreditCardIcon from "@mui/icons-material/CreditCard"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import { forwardRef, useMemo } from "react"
import { Link } from "react-router-dom"
import { useOrganization } from "../../../hooks/useOrganizationId"

// It's important to pass the ref to allow MUI to manage the keyboard navigation

export const SubscriptionMenu = forwardRef<HTMLAnchorElement>((props, ref) => {
  const { data: organization } = useOrganization()
  const url = useMemo(() => {
    if (["standard", "basic"].includes(organization?.tier)) {
      return "https://buy.stripe.com/bIY8A96b6fZZ83C6or"
    }
    return `https://billing.stripe.com/p/login/dR64iF70f9Mk9nGcMM?prefilled_email=${organization?.stripe_email}`
  }, [organization])
  return (
    <MenuItem
      ref={ref}
      component={Link}
      // It's important to pass the props to allow MUI to manage the keyboard navigation
      {...props}
      to="#"
      onClick={(event) => {
        event.preventDefault()
        window.open(url, "_blank")
      }}
    >
      <ListItemIcon>
        <CreditCardIcon />
      </ListItemIcon>
      <ListItemText>Subscription</ListItemText>
    </MenuItem>
  )
})
