import InputAdornment from "@mui/material/InputAdornment"
import { NumberInput, useRecordContext } from "react-admin"

export function TransferQuantityInput({ source }: { source: string }) {
  const { [source]: totalQuantity } = useRecordContext()
  if (totalQuantity === 1) return null
  return (
    <NumberInput
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">/{totalQuantity}</InputAdornment>
        ),
      }}
      inputProps={{ style: { textAlign: "right" } }}
      source={source}
      type="numeric"
      step={1}
      max={totalQuantity}
      min={1}
      validate={(value) => {
        if (value > totalQuantity) return "Quantity must be less than total"
        if (value < 1) return "Quantity must be greater than 0"
      }}
    />
  )
}
