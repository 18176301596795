import { useTranslate } from "react-admin"
import { useWatch } from "react-hook-form"
import { IntervalInput } from "./IntervalInput"

export function ServiceIntervalInput(props) {
  const is_recurring = useWatch({ name: "is_recurring" })
  const translate = useTranslate()
  const label = translate(
    `resources.service_templates.fields.${
      is_recurring ? "every_interval" : "after_interval"
    }`
  )

  return <IntervalInput {...props} label={label} />
}
