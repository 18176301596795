import { FunctionField } from "react-admin"

export function PercentField(props) {
  return (
    <FunctionField
      {...props}
      render={(record) => {
        if (!record[props.source]) return null
        return record[props.source] !== "Infinity"
          ? `${record[props.source] * 100}%`
          : "∞"
      }}
    />
  )
}
