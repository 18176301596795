import QrCode2Icon from "@mui/icons-material/QrCode2"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import { forwardRef } from "react"
import { Link } from "react-router-dom"

export const QRCodeMenu = forwardRef<HTMLAnchorElement>((props, ref) => {
  return (
    <>
      <MenuItem
        ref={ref}
        component={Link}
        // It's important to pass the props to allow MUI to manage the keyboard navigation
        {...props}
        to="#"
        onClick={(event) => {
          event.preventDefault()
          window.open("https://tooltri.be/buy-vinyl-stickers", "_blank")
        }}
      >
        <ListItemIcon>
          <QrCode2Icon />
        </ListItemIcon>
        <ListItemText>Buy Vinyl QR Codes</ListItemText>
      </MenuItem>
      <MenuItem
        ref={ref}
        component={Link}
        // It's important to pass the props to allow MUI to manage the keyboard navigation
        {...props}
        to="#"
        onClick={(event) => {
          event.preventDefault()
          window.open("https://tooltri.be/buy-metal-tool-tags", "_blank")
        }}
      >
        <ListItemIcon>
          <QrCode2Icon />
        </ListItemIcon>
        <ListItemText>Buy Metal QR Codes</ListItemText>
      </MenuItem>
      <MenuItem
        ref={ref}
        component={Link}
        // It's important to pass the props to allow MUI to manage the keyboard navigation
        {...props}
        to="#"
        onClick={(event) => {
          event.preventDefault()
          window.open(
            "https://tooltri.be/buy-stainless-steel-tool-tags",
            "_blank"
          )
        }}
      >
        <ListItemIcon>
          <QrCode2Icon />
        </ListItemIcon>
        <ListItemText>Buy Stainless Steel QR Codes</ListItemText>
      </MenuItem>
    </>
  )
})
