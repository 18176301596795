import history from "history/browser"
import { stringify } from "query-string"
import { DataProvider } from "ra-core"
import { fetchUtils } from "react-admin"

// url: 'https://api.procore.com/rest/v1.0/companies',
// qs: {
//   page: 'SOME_INTEGER_VALUE',
//   per_page: 'SOME_INTEGER_VALUE',
//   include_free_companies: 'SOME_BOOLEAN_VALUE'
// },
// headers: {Authorization: 'Bearer REPLACE_BEARER_TOKEN'}
// };

// const { REACT_APP_PROCORE_API_URL } = process.env
// export async function getCompanies(params: {) {
//   const params = new URLSearchParams({
//     page: "1",
//     per_page: "100",
//     include_free_companies: "true",
//   })
//   const results = await fetch(
//     `${REACT_APP_PROCORE_API_URL}/companies?${params}`,
//     {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${getAccessToken()}`,
//       },
//     }
//   )
//   const json = await results.json()
//   return json
// }

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
// eslint-disable-next-line import/no-anonymous-default-export
const buildRestProvider = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  countHeader: string = "Content-Range"
): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    // const { field, order } = params.sort

    const query = {
      page,
      per_page: perPage,
    } as any
    for (const key in params.filter) {
      if (key !== "company_id" || resource !== "cost_codes") {
        query[`filters[${key}]`] = params.filter[key]
      }
    }
    const options = {} as fetchUtils.Options
    const companyId = params.filter.company_id
    if (["projects", "images", "cost_codes"].includes(resource)) {
      if (companyId) {
        query.company_id = companyId
        options.headers = new Headers({
          ...options.headers,
          "Procore-Company-Id": companyId,
        })
      }
    }
    if (["cost_codes"].includes(resource)) {
      query.project_id = params.filter.project_id
    }

    const url = ["people", "users"].includes(resource)
      ? `${apiUrl}/companies/${companyId}/${resource}?${stringify(query)}`
      : `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url, options).then(({ headers, json }) => {
      // if (!headers.has(countHeader)) {
      //   throw new Error(
      //     `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
      //   )
      // }
      return {
        data: json,
        total: parseInt(headers.get("Total")),
        //   countHeader === "Content-Range"
        //     ? parseInt(headers.get("content-range").split("/").pop(), 10)
        //     : parseInt(headers.get(countHeader.toLowerCase())),
      }
    })
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      "filters[id]": params.ids,
    } as any
    const options = {} as fetchUtils.Options
    // Consider just excluding the company route
    const companyId = getCompanyId()
    if (["projects", "images", "people", "cost_codes"].includes(resource)) {
      if (companyId) {
        query.company_id = companyId
        options.headers = new Headers({
          ...(options.headers || {}),
          "Procore-Company-Id": companyId,
        })
      }
    }

    const url = ["people", "users"].includes(resource)
      ? `${apiUrl}/companies/${companyId}/${resource}?${stringify(query)}`
      : `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url, options).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    // const { field, order } = params.sort

    // const rangeStart = (page - 1) * perPage
    // const rangeEnd = page * perPage - 1

    const query = {
      // sort: JSON.stringify([field, order]),
      page: JSON.stringify(page),
      per_page: JSON.stringify(perPage),
      // filter: JSON.stringify({
      //   ...params.filter,
      //   [params.target]: params.id,
      // }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    const options = {}

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        )
      }
      return {
        data: json,
        total:
          countHeader === "Content-Range"
            ? parseInt(headers.get("content-range").split("/").pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase())),
      }
    })
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "text/plain",
      }),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          headers: new Headers({
            "Content-Type": "text/plain",
          }),
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    })),
})
const {
  REACT_APP_PROCORE_KEY,
  REACT_APP_PROCORE_LOGIN_URL,
  REACT_APP_PROCORE_API_URL,
  REACT_APP_PROCORE_DEMO_KEY,
  REACT_APP_PROCORE_DEMO_LOGIN_URL,
  REACT_APP_PROCORE_DEMO_API_URL,
} = process.env

const isDemo = JSON.parse(localStorage.getItem("demo")) ?? false
export const procoreKey = isDemo
  ? REACT_APP_PROCORE_DEMO_KEY
  : REACT_APP_PROCORE_KEY
export const procoreApiUrl = isDemo
  ? REACT_APP_PROCORE_DEMO_API_URL
  : REACT_APP_PROCORE_API_URL
export const procoreLoginUrl = isDemo
  ? REACT_APP_PROCORE_DEMO_LOGIN_URL
  : REACT_APP_PROCORE_LOGIN_URL

export const procoreAuthUrl = (redirectUrl) =>
  `${procoreLoginUrl}/oauth/authorize?client_id=${procoreKey}&response_type=token&redirect_uri=${redirectUrl}`

const urlSearchParams = new URLSearchParams(window.location.hash.substring(1))
if (urlSearchParams.has("access_token") && !urlSearchParams.has("type")) {
  localStorage.setItem(
    "procore_access_token",
    urlSearchParams.get("access_token")
  )
  const expires_in = urlSearchParams.get("expires_in")
  const expires_at = new Date().getTime() + parseInt(expires_in) * 1000
  localStorage.setItem("procore_expires_at", expires_at.toString())
  window.location.hash = ""
}

function getAccessToken(allowRedirect = true) {
  const expires_at = localStorage.getItem("procore_expires_at")
  if (expires_at && parseInt(expires_at) > new Date().getTime()) {
    return localStorage.getItem("procore_access_token")
  }
  if (allowRedirect) {
    const { pathname } = history.location
    const location = document.location.origin + pathname
    window.open(procoreAuthUrl(location), "_self")
  }
  return undefined
}

function getCompanyId() {
  return localStorage.getItem("procore_company_id")
}
export function setCompanyId(companyId) {
  localStorage.setItem("procore_company_id", companyId)
}

export const dataProvider = buildRestProvider(
  procoreApiUrl,
  (url, options = {}) => {
    const accessToken = getAccessToken()
    if (accessToken) {
      options.headers = new Headers({
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      })
    }

    return fetchUtils.fetchJson(url, options)
  }
)
