import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin"

import { useMemo } from "react"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { consumablesReportExporter } from "./consumablesReportExporter"

const ConsumablesReportFilters = (organization_id) => [
  <ReferenceInput
    key="project"
    reference="projects"
    source="project_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      organization_id,
      "deleted_at@is": null,
    }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="project_name"
      optionText="project_name"
      filterToQuery={(query) => ({ "project_name@ilike": query })}
    />
  </ReferenceInput>,
]

export const ConsumablesReportList = () => {
  const [organization_id] = useOrganizationId()
  const filters = useMemo(
    () => ConsumablesReportFilters(organization_id),
    [organization_id]
  )
  return (
    <List
      exporter={consumablesReportExporter}
      sort={{ field: "consumed_date", order: "DESC" }}
      filter={{ organization_id }}
      filters={filters}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField reference="projects" source="project_id" link="show" />
        <ReferenceField reference="tools" source="tool_id" link="show" />
        <ReferenceField
          reference="tools"
          source="tool_id"
          link={null}
          label="Quantity"
        >
          <TextField source="quantity" />
        </ReferenceField>
        <DateField showTime source="consumed_date" />
        <ReferenceField
          reference="tools"
          source="tool_id"
          link={false}
          label="Value"
        >
          <TextField source="value" />
        </ReferenceField>
        <ReferenceField
          reference="tools"
          source="tool_id"
          link={false}
          label="Total Value"
        >
          <FunctionField
            render={(record) =>
              `$${((record.value?.substring(1) ?? 0) * record.quantity).toFixed(
                2
              )}`
            }
          />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
