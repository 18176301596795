import { SimpleForm } from "@react-admin/ra-rbac"
import { snakeCase } from "lodash-es"
import { useMemo } from "react"
import {
  AutocompleteInput,
  BooleanInput,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
} from "react-admin"
import {
  useOrganization,
  useOrganizationId,
} from "../../hooks/useOrganizationId"
import { CrewMemberInput } from "./CrewMemberInput"
import { inactiveStatuses } from "./inactiveStatuses"
import { ProjectInput } from "./ProjectInput"
import { UtilizationInput } from "./UtilizationInput"
export function ToolForm(props) {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm
      defaultValues={{
        organization_id,
        tool_photo:
          "https://liumzmagzqxtwcoqhphp.supabase.co/storage/v1/object/public/static/images/no-photo.png",
      }}
      {...props}
    >
      <InnerForm />
    </SimpleForm>
  )
}

function InnerForm() {
  const { data: organization } = useOrganization()
  const { id_column, organization_id } = organization || {}
  //TODO: move this into the app
  const finalId = useMemo(() => snakeCase(id_column), [id_column])
  if (!organization) return null
  return (
    <>
      {/* <TextField source="state" /> */}
      <ImageInput source="tool_photo">
        <ImageField source="src" />
      </ImageInput>
      <ImageInput source="label_photo">
        <ImageField source="src" />
      </ImageInput>
      <TextInput source={finalId} label="Item ID#" />
      <TextInput source="name" validate={required()} fullWidth />
      <CrewMemberInput source="sender_id" organization_id={organization_id} />
      <ProjectInput source="project_id" organization_id={organization_id} />
      <SelectInput
        source="inactive_status"
        choices={[{ id: "ACTIVE", name: "Active" }, ...inactiveStatuses]}
        parse={(value) => (value === "ACTIVE" ? null : value)}
        format={(value) => (value === null ? "ACTIVE" : value)}
      />
      <NumberInput source="quantity" type="numeric" step={1} defaultValue={1} />
      <BooleanInput source="is_consumable" />
      <ReferenceInput
        key="category"
        reference="org_categories"
        source="category"
        filter={{ organization_id, "deleted_at@is": null }}
        // It's not respecting the sort order from the view; why not?
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
      >
        <AutocompleteInput
          validate={required()}
          fullWidth
          source="name"
          optionText="name"
          filterToQuery={(query) => ({
            "name@ilike": query,
          })}
        />
      </ReferenceInput>
      <ReferenceInput
        key="brand"
        reference="brands"
        source="brand"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
      >
        <AutocompleteInput
          fullWidth
          source="name"
          optionText="name"
          filterToQuery={(query) => ({ "name@ilike": query })}
        />
      </ReferenceInput>
      <TextInput multiline source="description" fullWidth rows={3} />
      <TextInput source="value" />
      <TextInput source="rental_rent" />
      <TextInput source="purchase_order" />
      <TextInput source="serial_number" />
      <UtilizationInput
        source="utilization"
        unitSource="utilization_unit"
        enabledSource="track_utilization"
      />
      <BooleanInput source="enable_procore_daily_log" />
      <FileInput source="attachments" multiple>
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
    </>
  )
}
