import jsonExport from "jsonexport/dist"
import { downloadCSV } from "react-admin"

export async function BrandsExporter(brands: any[], _: any, dataProvider: any) {
  const brandsForExport = await Promise.all(
    brands.map(async (brand) => {
      const {
        Brand_id,
        // name,
        organization_id,
        created_at,
        updated_at,
        deleted_at,
        ...brandForExport
      } = brand

      return brandForExport
    })
  )

  const csv = await jsonExport(brandsForExport, {
    headers: ["name"], // order fields in the export
  })
  downloadCSV(csv, "Brands")
}
