import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
export function ReorderForm(props) {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm>
      <ReferenceInput
        source="group_id"
        reference="groups"
        filter={{ organization_id }}
      >
        <AutocompleteInput
          disabled
          source="name"
          optionText="name"
          filterToQuery={(query) => ({ "name@ilike": query })}
        />
      </ReferenceInput>
      <ReferenceInput
        source="project_id"
        reference="projects"
        filter={{ organization_id, "deleted_at@is": "NULL" }}
      />
      <NumberInput source="reorder_point" />
      <NumberInput source="reordered_quantity" />
      <DateInput source="reordered_at" />
    </SimpleForm>
  )
}
