export enum InactiveStatus {
  Consumed = "CONSUMED",
  OutOfStock = "OOS",
  Destroyed = "DESTROYED",
  Hidden = "HIDDEN",
  Lost = "LOST",
  Repair = "REPAIR",
  Down = "DOWN",
  Stolen = "STOLEN",
  Retired = "RETIRED",
}
export const inactiveStatuses = [
  { id: InactiveStatus.Consumed, name: "Consumed" },
  { id: InactiveStatus.OutOfStock, name: "Out of stock" },
  { id: InactiveStatus.Repair, name: "Out for repair" },
  { id: InactiveStatus.Down, name: "Equipment down" },
  { id: InactiveStatus.Hidden, name: "Hidden" },
  { id: InactiveStatus.Lost, name: "Lost" },
  { id: InactiveStatus.Stolen, name: "Stolen" },
  { id: InactiveStatus.Destroyed, name: "Destroyed" },
  { id: InactiveStatus.Retired, name: "Retired" },
]

export const allStatuses = [{ id: null, name: "Active" }, ...inactiveStatuses]

export const labelFromInactiveStatus = (status: InactiveStatus | null) => {
  if (status === null) {
    return "Active"
  }
  return inactiveStatuses.find((s) => s.id === status)?.name
}
