import { useMemo } from "react"
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { PercentField } from "../Components/PercentField"
const AccountingReportFilters = (organization_id) => [
  <ReferenceInput
    key="project"
    reference="projects"
    source="project_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      organization_id,
      "deleted_at@is": null,
    }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="project_name"
      optionText="project_name"
      filterToQuery={(query) => ({ "project_name@ilike": query })}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="tool"
    reference="tools"
    source="tool_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      organization_id,
      "deleted_at@is": null,
    }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="display_name"
      optionText="display_name"
      filterToQuery={(query) => ({ "display_name@ilike": query })}
    />
  </ReferenceInput>,
]
export function AccountingReportList() {
  const [organization_id] = useOrganizationId()
  const filters = useMemo(
    () => AccountingReportFilters(organization_id),
    [organization_id]
  )
  return (
    <List
      filter={{
        organization_id,
      }}
      filters={filters}
    >
      <Datagrid rowClick="edit">
        <ReferenceField source="project_id" reference="projects" />
        <TextField source="category" />
        <ReferenceField source="tool_id" reference="tools" />
        <DateField source="period_start" />
        <DateField source="period_end" />
        <TextField source="rental_rent" />
        <TextField source="period_rent" />
        <TextField source="bill_type" />
        <TextField source="value" />
        <NumberField source="quantity" />
        <TextField source="capped_rent" />
        <TextField source="remaining_cap" />
        <PercentField source="cap_percentage" />
        <TextField source="cost_code" />
      </Datagrid>
    </List>
  )
}
