import { useOrganization } from "../../../hooks/useOrganizationId"

export function Logo() {
  // TODO: Add Pro or not pro here
  const { data: organization } = useOrganization()
  const src =
    organization.tier === "standard"
      ? "/images/tooltribe.webp"
      : "/images/tooltribe-pro.webp"
  return (
    <img
      src={src}
      alt="TOOLTRIBE Logo"
      style={{ height: 32, marginRight: 28, marginBottom: 6 }}
    />
  )
}
