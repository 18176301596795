import {
  ArrayInput,
  BooleanInput,
  FileField,
  FileInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { IntervalInput } from "../../Components/IntervalInput"
import { ServiceIntervalInput } from "../../Components/ServiceIntervalInput"

export function ServiceTemplateForm() {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm defaultValues={{ organization_id }}>
      <TextInput source="name" />
      <TextInput source="description" fullWidth rows={3} />
      <BooleanInput source="is_recurring" />
      <ServiceIntervalInput source="service_interval" />
      <IntervalInput source="reminder_interval" label="Reminder Interval" />
      <FileInput source="attachment" multiple={false}>
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
      <ArrayInput source="checklist">
        <SimpleFormIterator inline>
          <TextInput source="" label="Step" />
        </SimpleFormIterator>
      </ArrayInput>
      {/* <ReferenceManyToManyInput
        reference="tools"
        through="tool_services"
        using="service_template_id,tool_id"
        label="resources.service_templates.fields.tools"
        filter={{
          organization_id,
          "deleted_at@is": "NULL",
          "tool_photo@not.is": null,
          "inactive_status@not.in": `(${InactiveStatus.Consumed},${InactiveStatus.Lost},${InactiveStatus.Stolen},${InactiveStatus.Destroyed})`,
        }}
      >
        <AutocompleteArrayInput
          label="resources.service_templates.fields.tools"
          source="display_name"
          optionText="display_name"
          filterToQuery={(query) => ({
            "name@ilike": query,
          })}
        />
      </ReferenceManyToManyInput> */}
    </SimpleForm>
  )
}
