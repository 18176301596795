import { SaveButton, Toolbar } from "react-admin"
import { useFormState } from "react-hook-form"

export function ToolTransferToolbar() {
  const { dirtyFields } = useFormState()
  // we only want to only submit if you've set the crew member or project when transferring
  const disabled = !(dirtyFields.sender_id || dirtyFields.project_id)
  return (
    <Toolbar>
      <SaveButton label="ra.action.transfer" disabled={disabled} />
    </Toolbar>
  )
}
