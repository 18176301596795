import { useGetOne, useStore } from "react-admin"
import { NIL } from "uuid"
export function useOrganizationId() {
  return useStore("organizationId", NIL)
}

export function useOrganization() {
  const [organizationId] = useOrganizationId()
  return useGetOne("organizations", { id: organizationId })
}
