import {
  Datagrid,
  ImageField,
  List,
  TextField,
  useListContext,
  useRedirect,
} from "react-admin"
import { setCompanyId } from "../../procore"
export const ProcoreCompanyList = () => (
  <List>
    <ProcoreCompanyDataGrid />
  </List>
)

const ProcoreCompanyDataGrid = () => {
  const url = new URL(window.location.href)
  const resource = url.searchParams.get("resource")
  const companyId = url.searchParams.get("companyId")

  const { total, data } = useListContext()
  const redirect = useRedirect()
  if (companyId) {
    setCompanyId(companyId)
    redirect(`/procore/companies/${companyId}/${resource}`)
    return null
  }
  if (total === 1) {
    setCompanyId(data[0].id)
    redirect(`/procore/companies/${data[0].id}/${resource}`)
    return null
  }
  return (
    <Datagrid
      bulkActionButtons={false}
      rowClick={(id) => {
        setCompanyId(id)
        return `/procore/companies/${id}/${resource}`
      }}
    >
      <ImageField source="logo_url" />
      <TextField source="name" />
    </Datagrid>
  )
}
