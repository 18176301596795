import InputAdornment from "@mui/material/InputAdornment"
import { Edit, SimpleForm, SimpleShowLayout } from "@react-admin/ra-rbac"
import {
  BooleanInput,
  ImageField,
  ImageInput,
  NumberInput,
  TextField,
  useRecordContext,
  WithRecord,
} from "react-admin"
import { useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { ThumbnailField } from "../../Components/ThumbnailField"
import { CrewMemberInput } from "../CrewMemberInput"
import { ProjectInput } from "../ProjectInput"
import { InactiveStatus } from "../inactiveStatuses"
import { ToolState } from "../toolStates"
import { ToolTransferToolbar } from "./ToolTransferToolbar"
import { TransferQuantityInput } from "./TransferQuantityInput"

export function ToolTransfer() {
  const [organization_id] = useOrganizationId()
  const { toolId } = useParams()
  return (
    <Edit
      id={toolId}
      actions={false}
      transform={(record, { previousData }) => {
        if (record.inactive_status === false) record.inactive_status = null
        // Add confirmation here if they are transferring with confirmation.
        // TODO: consider handling this on the server side. If next_project_id or recipient_id is set on an active record, set project_id and sender_id to those values instead.
        if (record.state === ToolState.Transferring) {
          record.recipient_id = record.sender_id
          record.sender_id = previousData.sender_id
          record.next_project_id = record.project_id
          record.project_id = previousData.project_id
        } else if (record.inactive_status === InactiveStatus.Consumed) {
          record.state = ToolState.Inactive
        }
        return {
          ...record,
        }
      }}
    >
      <SimpleShowLayout>
        <ThumbnailField source="primary_photo" src="src" />
        <TextField source="display_name" label="Tool" />
        <TextField source="category" />
      </SimpleShowLayout>
      <SimpleForm toolbar={<ToolTransferToolbar />}>
        <CrewMemberInput source="sender_id" organization_id={organization_id} />
        <ProjectInput source="project_id" organization_id={organization_id} />
        <BooleanInput
          source="state"
          label="Require Confirmation"
          parse={(checked) => {
            if (checked) {
              return ToolState.Transferring
            }
            return ToolState.Active
          }}
          format={(value) => value === ToolState.Transferring}
        />
        <ConsumeInput />
        <TransferQuantityInput source="quantity" />
        <ImageInput source="transfer_photo">
          <ImageField source="src" />
        </ImageInput>
        <WithRecord
          render={({ track_utilization, utilization_unit }) =>
            track_utilization ? (
              <NumberInput
                source="utilization"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {utilization_unit &&
                        `${
                          utilization_unit.charAt(0).toUpperCase() +
                          utilization_unit.slice(1)
                        }s`}
                    </InputAdornment>
                  ),
                }}
              />
            ) : null
          }
        />
      </SimpleForm>
    </Edit>
  )
}

const ConsumeInput = () => {
  const { is_consumable } = useRecordContext()
  const state = useWatch({ name: "state" })
  if (!is_consumable || state !== ToolState.Active) return null
  return (
    <BooleanInput
      source="inactive_status"
      label="Mark Consumed"
      parse={(checked) => {
        if (checked) {
          return InactiveStatus.Consumed
        }
        // parse returning actual null causes the value to be set to false since
        // null from parse means "don't parse this value"
        return "null"
      }}
      format={(value) => value === InactiveStatus.Consumed}
    />
  )
}
