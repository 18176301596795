import UploadIcon from "@mui/icons-material/Upload"
import {
  Button,
  Datagrid,
  List,
  TextField,
  useCreate,
  useGetList,
  useListContext,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { useParams } from "react-router-dom"
import { useOrganizationId } from "../../hooks/useOrganizationId"

const ProcoreCostCodeImportButton = () => {
  const { company_id: procore_company_id, project_id: procore_project_id } =
    useParams()

  const { resource, data, selectedIds } = useListContext()
  const [organization_id] = useOrganizationId()
  const notify = useNotify()
  const unselectAll = useUnselectAll(resource)

  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )
  const [create, { isLoading }] = useCreate("cost_codes")
  const {
    data: projectData,
    // total,
    // isPending,
    // error,
    // refetch,
  } = useGetList("projects", {
    pagination: { page: 1, perPage: 1 },
    filter: {
      organization_id,
      procore_company_id,
      procore_project_id,
      "deleted_at@is": "NULL",
    },
  })
  const project_id = projectData?.[0].id
  if (!project_id) return null
  return (
    <Button
      label="ra.action.procore.cost_codes.import.selected"
      disabled={isLoading}
      onClick={async () => {
        await Promise.all(
          selectedRecords.map((record) =>
            create(
              "cost_codes",
              {
                data: {
                  organization_id,
                  full_code: record.full_code,
                  cost_code_name: record.name,
                  project_id,
                  procore_company_id,
                  procore_cost_code_id: record.id,
                },
              },
              {
                onSuccess: () => {
                  notify(`ra.action.procore.cost_codes.import.notification`, {
                    type: "success",
                    messageArgs: { smart_count: selectedRecords.length },
                  })
                  unselectAll()
                },
              }
            )
          )
        )
      }}
    >
      <UploadIcon />
    </Button>
  )
}

export const ProcoreCostCodeList = () => {
  const { company_id, project_id } = useParams()

  return (
    <List
      exporter={false}
      resource="procore/cost_codes"
      filter={{ company_id, project_id }}
    >
      <Datagrid
        resource="procore/cost_codes"
        bulkActionButtons={<ProcoreCostCodeImportButton />}
      >
        <TextField source="full_code" />
        <TextField source="name" />
      </Datagrid>
    </List>
  )
}
