import {
  Provider,
  SupabaseClient,
  User,
  VerifyEmailOtpParams,
} from "@supabase/supabase-js"
import { AuthProvider, UserIdentity } from "ra-core"
import { getStorage } from "react-admin"
import { supabase } from "./supabase"

const supabaseAuthProvider = (
  client: SupabaseClient,
  { getIdentity, getPermissions, redirectTo }: SupabaseAuthProviderOptions
): SupabaseAuthProvider => {
  return {
    async login(params) {
      const emailPasswordParams = params as LoginWithEmailPasswordParams
      if (emailPasswordParams.email && emailPasswordParams.password) {
        const { error } = await client.auth.signInWithPassword(
          emailPasswordParams
        )

        if (error) {
          throw error
        }

        return
      }

      const oauthParams = params as LoginWithOAuthParams
      if (oauthParams.provider) {
        client.auth.signInWithOAuth({
          ...oauthParams,
          options: { redirectTo },
        })
        // To avoid react-admin to consider this as an immediate success,
        // we return a rejected promise that is handled by the default OAuth login buttons
        return Promise.reject()
      }

      const otpParams = params as VerifyEmailOtpParams
      if (otpParams.email && otpParams.token) {
        const { error } = await client.auth.verifyOtp({
          email: otpParams.email,
          token: otpParams.token,
          type: "email",
        })

        if (error) {
          throw error
        }

        return Promise.resolve()
      }

      const magicLinkParams = params as LoginWithMagicLink
      if (magicLinkParams.email) {
        const { error } = await client.auth.signInWithOtp({
          email: magicLinkParams.email,
        })

        if (error) {
          throw error
        }

        return Promise.reject()
      }

      return Promise.reject(new Error("Invalid login parameters"))
    },
    async setPassword({
      access_token,
      refresh_token,
      password,
    }: SetPasswordParams) {
      const { error: sessionError } = await client.auth.setSession({
        access_token,
        refresh_token,
      })

      if (sessionError) {
        throw sessionError
      }
      const { error } = await client.auth.updateUser({
        password,
      })

      if (error) {
        throw error
      }
      return undefined
    },
    async resetPassword(params: ResetPasswordParams) {
      const { email, ...options } = params

      const { error } = await client.auth.resetPasswordForEmail(email, options)

      if (error) {
        throw error
      }
      return undefined
    },
    async logout() {
      const { error } = await client.auth.signOut()
      if (error) {
        throw error
      }
    },
    async checkError(error) {
      if (error.status === 401 || error.status === 403) {
        return Promise.reject()
      }

      return Promise.resolve()
    },
    async handleCallback() {
      const { access_token, refresh_token, type } = getUrlParams()

      // Users have reset their password or have just been invited and must set a new password
      if (type === "recovery" || type === "invite") {
        if (access_token && refresh_token) {
          return {
            redirectTo: `${
              redirectTo ? `${redirectTo}/` : "/"
            }set-password?access_token=${access_token}&refresh_token=${refresh_token}&type=${type}`,
          }
        }

        if (process.env.NODE_ENV === "development") {
          console.error(
            "Missing access_token or refresh_token for an invite or recovery"
          )
        }
      }
    },
    async checkAuth() {
      // Users are on the set-password page, nothing to do
      if (
        window.location.pathname === "/set-password" ||
        window.location.pathname === "/verify-code"
      ) {
        return
      }
      // Users are on the forgot-password page, nothing to do
      if (window.location.pathname === "/forgot-password") {
        return
      }

      const { access_token, refresh_token, type } = getUrlParams()

      // Users have reset their password or have just been invited and must set a new password
      if (type === "recovery" || type === "invite") {
        if (access_token && refresh_token) {
          // eslint-disable-next-line no-throw-literal
          throw {
            redirectTo: `${
              redirectTo ? `${redirectTo}/` : "/"
            }set-password?access_token=${access_token}&refresh_token=${refresh_token}&type=${type}`,
            message: false,
          }
        }

        if (process.env.NODE_ENV === "development") {
          console.error(
            "Missing access_token or refresh_token for an invite or recovery"
          )
        }
      }

      const { data } = await client.auth.getSession()
      if (data.session == null) {
        return Promise.reject()
      }

      return Promise.resolve()
    },
    async getPermissions() {
      const {
        data: { session },
      } = await client.auth.getSession()
      if (session == null) {
        return
      }
      const { data, error } = await client.auth.getUser()
      if (error) {
        throw error
      }
      if (data.user == null) {
        return undefined
      }

      if (typeof getPermissions === "function") {
        const permissions = await getPermissions(data.user)
        return permissions
      }
      return undefined
    },
    async getIdentity() {
      if (
        window.location.pathname === "/set-password" ||
        window.location.pathname === "/verify-code"
      ) {
        return
      }
      const { data } = await client.auth.getUser()

      if (data.user == null) {
        throw new Error()
      }

      if (typeof getIdentity === "function") {
        const identity = await getIdentity(data.user)
        return identity
      }

      return undefined
    },
  }
}

export type GetIdentity = (user: User) => Promise<UserIdentity>
export type GetPermissions = (user: User) => Promise<any>
export type SupabaseAuthProviderOptions = {
  getIdentity?: GetIdentity
  getPermissions?: GetPermissions
  redirectTo?: string
}

type LoginWithEmailPasswordParams = {
  email: string
  password: string
}

type LoginWithOAuthParams = {
  provider: Provider
}

type LoginWithMagicLink = {
  email: string
}

export interface SupabaseAuthProvider extends AuthProvider {
  login: (
    params:
      | LoginWithEmailPasswordParams
      | LoginWithMagicLink
      | LoginWithOAuthParams
  ) => ReturnType<AuthProvider["login"]>
  setPassword: (params: SetPasswordParams) => Promise<void>
  resetPassword: (params: ResetPasswordParams) => Promise<void>
}

export type SetPasswordParams = {
  access_token: string
  refresh_token: string
  password: string
}

export type ResetPasswordParams = {
  email: string
  redirectTo?: string
  captchaToken?: string
}

supabase.auth.onAuthStateChange((event, session) => {
  if (event !== "SIGNED_IN") return
  const storage = getStorage()
  const organizationId = storage.getItem("RaStore.organizationId")
  if (organizationId) return
  supabase
    .from("crew_members")
    .select("organization_id")
    .is("deleted_at", null)
    .eq("user_id", session?.user?.id)
    .then(({ data }) => {
      if (data.length === 0) return
      storage.setItem(
        "RaStore.organizationId",
        // Somehow this is getting quoted on chrome on windows so we are removing that all over
        data[0].organization_id.replaceAll('"', "")
      )
    })
})

const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.hash.substring(1))

  const access_token = urlSearchParams.get("access_token")
  const refresh_token = urlSearchParams.get("refresh_token")
  const type = urlSearchParams.get("type")

  return { access_token, refresh_token, type }
}

export const authProvider = supabaseAuthProvider(supabase, {
  getIdentity: async (user) => {
    if (!user.id) {
      return
    }
    const { data: organizationObjs } = await supabase
      .from("crew_members")
      .select("organization_id,role,redirect_url")
      .is("deleted_at", null)
      .eq("user_id", user.id)
    // We use crew rather than orgs to make sure the user is part of the organization
    const organizations =
      organizationObjs?.map((org) => org.organization_id) ?? []
    const storage = getStorage()
    const organizationId = storage
      .getItem("RaStore.organizationId")
      ?.replaceAll('"', "")

    if (organizationId == null && !organizations.includes(organizationId)) {
      storage.setItem("RaStore.organizationId", organizations[0])
    }
    const currentOrganization =
      organizationId == null
        ? organizations[0]
        : organizationObjs.find((org) => org.organization_id === organizationId)

    const role = currentOrganization?.role
    const redirectUrl = currentOrganization?.redirect_url
    return {
      id: user.id,
      fullName: user.user_metadata?.full_name,
      email: user.email,
      claims: user.app_metadata,
      organizations,
      role,
      redirectUrl,
    }
  },
  getPermissions: async (user) => {
    const storage = getStorage()
    const organizationId = storage
      .getItem("RaStore.organizationId")
      ?.replaceAll('"', "")

    const match = {}
    if (organizationId) {
      match["organization_id"] = organizationId
    }
    const { data, error } = await supabase
      .from("my_permissions")
      .select()
      .match(match)
      .limit(1)
      .maybeSingle()
    // As a convention we return null if the user is not part of an organization
    if (!data) return null
    if (error) throw error

    if (data.role === "observer") {
      return [
        { type: "allow", resource: "*", action: "read" },
        { type: "allow", resource: "*", action: "list" },
        { type: "allow", resource: "*", action: "export" },
      ]
    }

    if (data.allow_admin && data.allow_tool_scans) {
      return [{ resource: "*", action: "*" }]
    }

    if (data.allow_admin)
      return [
        { resource: "*", action: "*" },
        { type: "deny", resource: "tool_scans", action: "*" },
      ]

    const permissions = [
      { type: "allow", action: "read", resource: "*" },
      { type: "allow", action: "list", resource: "*" },
      { type: "allow", action: "export", resource: "*" },
      { type: "deny", action: "delete", resource: "*" },
    ]
    if (!data.allow_tool_scans) {
      permissions.push({ type: "deny", resource: "tool_scans", action: "*" })
    }
    if (data.allow_add_edit_tools) {
      permissions.push({ type: "allow", action: "*", resource: "tools" })
      permissions.push({ type: "allow", action: "write", resource: "tools.*" })
      permissions.push({ type: "allow", action: "*", resource: "maintenance" })
      permissions.push({
        type: "allow",
        action: "write",
        resource: "maintenance.*",
      })
    }
    if (data.allow_invite) {
      permissions.push({
        type: "allow",
        action: "create",
        resource: "crew_members",
      })
    }
    if (data.allow_edit_crew) {
      permissions.push({
        type: "allow",
        action: "edit",
        resource: "crew_members",
      })
      permissions.push({
        type: "allow",
        action: "write",
        resource: "crew_members.*",
      })
      permissions.push({ type: "allow", action: "*", resource: "projects" })
      permissions.push({
        type: "allow",
        action: "write",
        resource: "projects.*",
      })
    }
    if (data.allow_edit_organization) {
      permissions.push({
        type: "allow",
        action: "read",
        resource: "organizations",
      })
      permissions.push({
        type: "allow",
        action: "edit",
        resource: "organizations",
      })
      permissions.push({
        type: "allow",
        action: "write",
        resource: "organizations.*",
      })
    }

    return permissions
  },
})
